/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantMarkedForDeletion
 */
export interface TenantMarkedForDeletion {
    /**
     * 
     * @type {string}
     * @memberof TenantMarkedForDeletion
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantMarkedForDeletion
     */
    deletionReservationId?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantMarkedForDeletion
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantMarkedForDeletion
     */
    modifyingUser?: string;
}

/**
 * Check if a given object implements the TenantMarkedForDeletion interface.
 */
export function instanceOfTenantMarkedForDeletion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TenantMarkedForDeletionFromJSON(json: any): TenantMarkedForDeletion {
    return TenantMarkedForDeletionFromJSONTyped(json, false);
}

export function TenantMarkedForDeletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantMarkedForDeletion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'deletionReservationId': !exists(json, 'deletionReservationId') ? undefined : json['deletionReservationId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
    };
}

export function TenantMarkedForDeletionToJSON(value?: TenantMarkedForDeletion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'deletionReservationId': value.deletionReservationId,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
    };
}

