import { containerClasses } from '@mui/material';
import { maxWidth } from '@mui/system';
import React from 'react';
import ReactPlayer from 'react-player/file';

import DisplayPdf from './DisplayPdf';

interface DisplayContentProp {
	mediaFile: string | Blob | File;
	mediaType: string;
	title: string;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DisplayContent(prop: DisplayContentProp) {
	const { mediaFile, mediaType, title, setAlertText, setShowErrorAlert } = prop;

	const getSrc = (mediaFile: string | Blob | File): string => {
		try {
			if (typeof mediaFile === 'string') {
				return mediaFile;
			} else {
				return URL.createObjectURL(mediaFile);
			}
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Die ausgewählte Mediendatei kann nicht angezeigt werden');
			return '';
		}
	};

	const displayContent = (
		mediaFile: string | Blob | File,
		mediaType: string,
		title: string
	) => {
		let errorMsg = <div>Die Datei kann nicht angezeigt werden.</div>;
		if (mediaType?.includes('image')) {
			try {
				return (
					<img
						height={window.innerHeight * 0.75}
						width={'auto'}
						src={getSrc(mediaFile)}
						alt='Anzuzeigende Bilddatei'
					/>
				);
			} catch {
				return errorMsg;
			}
		} else if (mediaType?.includes('pdf')) {
			try {
				return <DisplayPdf src={getSrc(mediaFile)} />;
			} catch {
				return errorMsg;
			}
		} else if (mediaType?.includes('text')) {
			return errorMsg;
		} else if (mediaType?.includes('video') || mediaType?.includes('audio')) {
			try {
				return (
					<ReactPlayer
						width={'fit-content'}
						playing
						controls
						url={getSrc(mediaFile)}
					/>
				);
			} catch {
				return errorMsg;
			}
		} else {
			return errorMsg;
		}
	};

	return (
		<div style={{overflowX: 'auto'}}>
			<React.Fragment>
				{displayContent(mediaFile, mediaType, title)}
			</React.Fragment>
		</div>
	);
}
