/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CertificateMetaData
 */
export interface CertificateMetaData {
    /**
     * 
     * @type {number}
     * @memberof CertificateMetaData
     */
    certificateId?: number;
    /**
     * 
     * @type {string}
     * @memberof CertificateMetaData
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateMetaData
     */
    objectKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateMetaData
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateMetaData
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateMetaData
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof CertificateMetaData
     */
    techValidFrom?: Date;
}

/**
 * Check if a given object implements the CertificateMetaData interface.
 */
export function instanceOfCertificateMetaData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CertificateMetaDataFromJSON(json: any): CertificateMetaData {
    return CertificateMetaDataFromJSONTyped(json, false);
}

export function CertificateMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificateId': !exists(json, 'certificateId') ? undefined : json['certificateId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'objectKey': !exists(json, 'objectKey') ? undefined : json['objectKey'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
    };
}

export function CertificateMetaDataToJSON(value?: CertificateMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificateId': value.certificateId,
        'tenantIdentifier': value.tenantIdentifier,
        'objectKey': value.objectKey,
        'fileName': value.fileName,
        'serialNumber': value.serialNumber,
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
    };
}

