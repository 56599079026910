/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    lastChanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    userLastChange?: string;
}

/**
 * Check if a given object implements the Tenant interface.
 */
export function instanceOfTenant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'lastChanged': !exists(json, 'lastChanged') ? undefined : (new Date(json['lastChanged'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userLastChange': !exists(json, 'userLastChange') ? undefined : json['userLastChange'],
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'lastChanged': value.lastChanged === undefined ? undefined : (value.lastChanged.toISOString().substr(0,10)),
        'description': value.description,
        'userLastChange': value.userLastChange,
    };
}

