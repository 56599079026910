import { useKeycloak } from '@react-keycloak/web';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import React from 'react';
import { ThemeProvider, CssBaseline, Typography, Box, CardActions, Divider, Button, CardMedia, Card } from '@mui/material';
import theme from '../theme';
import Tenants from '../pages/Tenants';
import Menu from '../components/Menu';
import Users from '../pages/Users';
import Residences from '../pages/Residences';
import Units from '../pages/Units';
import DisplayLocalMedia from '../components/DisplayLocalMedia';
import icon_tcc from '../data/icon_tcc_abgerundet.svg';

export const AppRouter = () => {
	const k = useKeycloak();

	const residencePath = '/tenantId=:tenantId/residences';
	const unitsPath =
		'/tenantId=:tenantId/residences/residenceId=:residenceId/units';
	const usersPath = '/tenantId=:tenantId/users';

	const [showMediaFile, setShowMediaFile] = React.useState(false);

	const handleInstruction = () => {
		setShowMediaFile(true);
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					{!k.keycloak.authenticated && <Menu />}
					{k.keycloak.authenticated ? (
						<>
							<Menu />
							<Switch>
								<Route exact path='/' component={Tenants} />
								<Route exact path={usersPath} component={Users} />
								<Route exact path={residencePath} component={Residences} />
								<Route exact path={unitsPath} component={Units} />
							</Switch>
						</>
					) : (
						<Box
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								width: '100%',
								height: '100%',
							}}>
							<Card
								sx={{
									maxWidth: 'min-content',
									borderRadius: '16px',
									height: '100%',
									marginTop: '10%',
								}}
								elevation={4}>
								<CardMedia
									component='img'
									height='100%'
									alt=''
									src={icon_tcc}
								/>
								<Divider />
								<CardActions>
									<Button fullWidth size='small' onClick={handleInstruction}>
										<Typography variant='subtitle2' align='center'>
											Benutzrerhandbuch CIAM anzeigen
										</Typography>
									</Button>
								</CardActions>
							</Card>
						</Box>
					)}
				</Router>
			</ThemeProvider>
			{showMediaFile && (
				<DisplayLocalMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					filePathInPublicDir='/data/BenutzerleitfadenCIAM.pdf'
					mediaType={'pdf'}
				/>
			)}
		</React.Fragment>
	);
};
