import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
} from '@mui/material';
import * as React from 'react';
import { Delete, Close, Warning } from '@mui/icons-material';
import { orange } from '@mui/material/colors';

export default function Confirm({ open, setOpen, text, executefct }: any) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					<Grid
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'
						spacing={2}>
						<Grid item>
							{
								<Warning
									sx={{ color: orange[500], fontSize: 40 }}
								/>
							}
						</Grid>
						<Grid item>Achtung</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction='column'
						justifyContent='flex-end'
						alignItems='center'
						spacing={2}>
						<Grid item>{text}</Grid>
						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='flex-end'
								spacing={2}>
								<Grid item>
									<Button
										variant='contained'
										onClick={handleClose}>
										{<Close />} Abbrechen
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										onClick={executefct}>
										{<Delete />}Ja
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
