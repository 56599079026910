import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Table,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';

import { PostUnitDeletionReservationRequest, Unit, UnitDeletionDecision, UnitMarkedForDeletion, ExecuteUnitDeletionDecisionRequest } from '../generated';
import { abbreviateString, ExpandMore, formatDate, isEmptyField, isUndefined, ParsedToken } from './format';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import keycloak from './keycloak';
import { Check, Close, Delete } from '@mui/icons-material';

interface UnitCardProps {
	unit: Unit;
	onClickCard: any;
	isAuthorizedGlobalAdmin: boolean;
	isAuthorizedTenantAdmin: boolean;
	handleClickMarkForDeletion: any;
	getMarkedForDeletion: any;
	handleClickDeletionDecision: any;
	handleClickDelete: any
}

export default function UnitCard({
	unit,
	onClickCard,
	isAuthorizedGlobalAdmin,
	isAuthorizedTenantAdmin,
	handleClickMarkForDeletion,
	getMarkedForDeletion,
	handleClickDeletionDecision,
	handleClickDelete
}: UnitCardProps) {
	let cardcolor = 'primary';
	const tenantIdentifier = unit?.tenantIdentifier as string;
	const residenceIdentifier = unit?.residenceIdentifier as string;
	const unitIdentifier = unit?.unitIdentifier as string;
	
	const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

	const [isExpanded, setIsExpanded] = useState(false);
	const [deletionReservation, setDeletionReservation] = useState(
		{
			tenantIdentifier: tenantIdentifier,
			residenceIdentifier: residenceIdentifier,
			unitIdentifier: unitIdentifier,
			unitDeletionReservationInsertParameters: {}
		} as PostUnitDeletionReservationRequest);
	const unitMarkedForDeletion: UnitMarkedForDeletion | undefined = getMarkedForDeletion(unit);

	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};

	const isDeleteButtonEnabled = () => {
		return unitMarkedForDeletion?.modifyingUser !== parsedToken?.preferred_username
	}

	const isReservationButtonEnabled = () => {
		return !isEmptyField(deletionReservation.unitDeletionReservationInsertParameters?.comment)
	}

	const handleCommentInput = (e: { target: { name: any; value: any; }; }) => {
		const { name, value } = e.target;
		setDeletionReservation({
			... deletionReservation,
			unitDeletionReservationInsertParameters: {comment: value}
		});
	};

	const handleDecision = (isTrue: boolean) => {
		let decision: UnitDeletionDecision = { isPerformDeletion: isTrue }
		let decisionUpdate: ExecuteUnitDeletionDecisionRequest =
		{
			tenantIdentifier: tenantIdentifier,
			residenceIdentifier: residenceIdentifier,
			unitIdentifier: unitIdentifier,
			unitDeletionReservationId: unitMarkedForDeletion?.deletionReservationId as number,
			unitDeletionDecision: decision
		}

		handleClickDeletionDecision(decisionUpdate);
	}

	const ImmediateDeletion = () => {
		return (
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Löschen des Wohnbereichs
					</Typography>
					<Typography>
						Diese Aktion entfernt endgültig alle Bewohnerdaten des Wohnbereichs
					</Typography>
				</Grid>
					<Grid item xs={12}>
					<Button
						variant='contained'
						disabled={!isAuthorizedGlobalAdmin}
						color='primary'
						onClick={handleClickDelete}>
						{<Delete />}Wohnereich Löschen
					</Button>
				</Grid>
			</Grid>
		)
	}

	const DeletionReservation = (deletionReservation: PostUnitDeletionReservationRequest) => {
		return (
			<Grid container spacing={2} paddingTop={4} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Zum Löschen vormerken
					</Typography>
					<Typography>
						Zur endgültigen Entfernung aller Daten muss ein:e Kollege:In zustimmen.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label='Erläuterung'
						name='comment'
						multiline
						maxRows={4}
						required
						id='outlined-size-normal'
						value={
							deletionReservation.unitDeletionReservationInsertParameters?.comment
						}
						onChange={handleCommentInput}
						inputProps={{ maxLength: 500 }} />
				</Grid>
				<Grid item xs={12}>
					<Button
						variant='contained'
						color='primary'
						disabled={!isReservationButtonEnabled()}
						onClick={() => handleClickMarkForDeletion(deletionReservation)}>
						{<Delete />}Wohnbereich zum Löschen vormerken
					</Button>
				</Grid>
			</Grid>
		)
	}

	const DeletionDecision = () => {
		return (
			<Grid container spacing={2} paddingTop={4} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Löschen bestätigen
					</Typography>
					<Typography>
						Zur endgültigen Entfernung aller Daten muss ein:e Kollege:In zustimmen.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table  >
						<TableRow>
							<TableCell variant="head">Vorgemerkt von</TableCell>
							<TableCell>{unitMarkedForDeletion?.modifyingUser}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Erläuterung</TableCell>
							<TableCell>{unitMarkedForDeletion?.comment}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Bestätigung</TableCell>
							<TableCell>
								<Tooltip title='Bestätige Löschung'>
									<Button
										disabled={!isDeleteButtonEnabled()}
										onClick={() => handleDecision(true)} >
										{< Check />}
									</Button>
								</Tooltip>
								<Tooltip title='Vormerkung rückgängig machen'>
									<Button
										onClick={() => handleDecision(false)} >
										{<Close color='primary' />}
									</Button>
								</Tooltip>
							</TableCell>
						</TableRow>
					</Table>
				</Grid>
			</Grid>
		)
	}


	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<CardActionArea onClick={onClickCard}>
				<Grid container spacing={2} direction='row'>
					<Grid item xs={12}>
						<CardContent>
							<Typography
								align='center'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(unit.unitIdentifier as string, 30)}
							</Typography>
						</CardContent>
					</Grid>
					<Grid item xs={12}>
						<Divider variant='middle'>
							{unit !== undefined ? (
								<Typography fontSize={12} color={'primary'} variant={'body2'}>
									{formatDate(unit?.lastChanged)}
								</Typography>
							) : (
								void 0
							)}
						</Divider>
					</Grid>
				</Grid>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}>
								{abbreviateString(unit?.description as string, 60)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<CardActions disableSpacing>
				<ExpandMore
					disabled={!isAuthorizedTenantAdmin}
					expand={isExpanded}
					onClick={handleExpandClick}
					aria-expanded={isExpanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<CardContent >
					{
						isUndefined(unitMarkedForDeletion) ? (
							isAuthorizedGlobalAdmin ? ImmediateDeletion() : DeletionReservation(deletionReservation)
						) :
							DeletionDecision()
					}
				</CardContent>
			</Collapse>
		</Card>
	);
}
