/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidenceDeletionDecision
 */
export interface ResidenceDeletionDecision {
    /**
     * 
     * @type {boolean}
     * @memberof ResidenceDeletionDecision
     */
    isPerformDeletion?: boolean;
}

/**
 * Check if a given object implements the ResidenceDeletionDecision interface.
 */
export function instanceOfResidenceDeletionDecision(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResidenceDeletionDecisionFromJSON(json: any): ResidenceDeletionDecision {
    return ResidenceDeletionDecisionFromJSONTyped(json, false);
}

export function ResidenceDeletionDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceDeletionDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPerformDeletion': !exists(json, 'isPerformDeletion') ? undefined : json['isPerformDeletion'],
    };
}

export function ResidenceDeletionDecisionToJSON(value?: ResidenceDeletionDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPerformDeletion': value.isPerformDeletion,
    };
}

