import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import theme from '../theme';
import { UploadFile } from '@mui/icons-material';

export default function FileUploader(props: any) {
	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Button variant='contained' color='primary'>
					<label onChange={props.onChange} htmlFor='formId'>
						<input name='' type='file' id='formId' hidden />
						<UploadFile style={{ verticalAlign: 'middle' }} />
						Auswählen
					</label>
				</Button>
			</ThemeProvider>
		</React.Fragment>
	);
}
