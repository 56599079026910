import { Alert, AlertTitle, Snackbar } from '@mui/material';

interface SuccessAlertProps {
	text: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SuccessAlert({ text, open, setOpen }: SuccessAlertProps) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<Alert severity='success'>
				<AlertTitle>Erfolg</AlertTitle>
				{text}
			</Alert>
		</Snackbar>
	);
}

interface ErrorAlertProps {
	text: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ErrorAlert({ text, open, setOpen }: ErrorAlertProps) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<Alert severity='error'>
				<AlertTitle>Fehler</AlertTitle>
				{text}
			</Alert>
		</Snackbar>
	);
}

interface InfoAlertProps {
	text: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function InfoAlert({ text, open, setOpen }: InfoAlertProps) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<Alert severity='info'>
				<AlertTitle>Info</AlertTitle>
				{text}
			</Alert>
		</Snackbar>
	);
}
