import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Collapse,
	Divider,
	Grid,
	Table,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import { ExecuteResidenceDeletionDecisionRequest, PostResidenceDeletionReservationRequest, Residence, ResidenceDeletionDecision, ResidenceMarkedForDeletion } from '../generated';
import { abbreviateString, ExpandMore, formatDate, isEmptyField, isUndefined, ParsedToken } from './format';
import { Chair, Check, Close, Delete } from '@mui/icons-material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import keycloak from './keycloak';
import { useState } from 'react';

interface ResidenceCardProps {
	residence: Residence;
	onClickCard: any;
	isAuthorizedGlobalAdmin: boolean;
	isAuthorizedTenantAdmin: boolean;
	handleClickUnits: any;
	handleClickMarkForDeletion: any;
	getMarkedForDeletion: any;
	handleClickDeletionDecision: any;
	handleClickDelete: any
}

export default function ResidenceCard({
	residence,
	onClickCard,
	isAuthorizedGlobalAdmin,
	isAuthorizedTenantAdmin,
	handleClickUnits,
	handleClickMarkForDeletion,
	getMarkedForDeletion,
	handleClickDeletionDecision,
	handleClickDelete
}: ResidenceCardProps) {
	let cardcolor = 'primary';

	const tenantIdentifier = residence?.tenantIdentifier as string;
	const residenceIdentifier = residence?.residenceIdentifier as string;
	
	const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

	const [isExpanded, setIsExpanded] = useState(false);
	const [deletionReservation, setDeletionReservation] = useState(
		{
			tenantIdentifier: tenantIdentifier,
			residenceIdentifier: residenceIdentifier,
			residenceDeletionReservationInsertParameters: {}
		} as PostResidenceDeletionReservationRequest);
	const residenceMarkedForDeletion: ResidenceMarkedForDeletion | undefined = getMarkedForDeletion(residence);

	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};

	const isDeleteButtonEnabled = () => {
		return residenceMarkedForDeletion?.modifyingUser !== parsedToken?.preferred_username
	}

	const isReservationButtonEnabled = () => {
		return !isEmptyField(deletionReservation.residenceDeletionReservationInsertParameters?.comment)
	}

	const handleCommentInput = (e: { target: { name: any; value: any; }; }) => {
		const { name, value } = e.target;
		setDeletionReservation({
			... deletionReservation,
			residenceDeletionReservationInsertParameters: {comment: value}
		});
	};

	const handleDecision = (isTrue: boolean) => {
		let decision: ResidenceDeletionDecision = { isPerformDeletion: isTrue }
		let decisionUpdate: ExecuteResidenceDeletionDecisionRequest =
		{
			tenantIdentifier: tenantIdentifier,
			residenceIdentifier: residenceIdentifier,
			residenceDeletionReservationId: residenceMarkedForDeletion?.deletionReservationId as number,
			residenceDeletionDecision: decision
		}
		handleClickDeletionDecision(decisionUpdate);
	}

	const ImmediateDeletion = () => {
		return (
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Löschen des Wohnheims
					</Typography>
					<Typography>
						Diese Aktion entfernt endgültig alle Daten des Wohnheims (Wohnbereiche, Bewohner)
					</Typography>
				</Grid>
					<Grid item xs={12}>
					<Button
						variant='contained'
						disabled={!isAuthorizedGlobalAdmin}
						color='primary'
						onClick={handleClickDelete}>
						{<Delete />}Wohnheim Löschen
					</Button>
				</Grid>
			</Grid>
		)
	}

	const DeletionReservation = (deletionReservation: PostResidenceDeletionReservationRequest) => {
		return (
			<Grid container spacing={2} paddingTop={4} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Zum Löschen vormerken
					</Typography>
					<Typography>
						Zur endgültigen Entfernung aller Daten muss ein:e Kollege:In zustimmen.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label='Erläuterung'
						name='comment'
						multiline
						maxRows={4}
						required
						id='outlined-size-normal'
						value={
							deletionReservation.residenceDeletionReservationInsertParameters?.comment
						}
						onChange={handleCommentInput}
						inputProps={{ maxLength: 500 }} />
				</Grid>
				<Grid item xs={12}>
					<Button
						variant='contained'
						color='primary'
						disabled={!isReservationButtonEnabled()}
						onClick={() => handleClickMarkForDeletion(deletionReservation)}>
						{<Delete />}Wohnheim zum Löschen vormerken
					</Button>
				</Grid>
			</Grid>
		)
	}

	const DeletionDecision = () => {
		return (
			<Grid container spacing={2} paddingTop={4} direction='row'>
				<Grid item xs={12}>
					<Typography variant='h6'>
						Löschen bestätigen
					</Typography>
					<Typography>
						Zur endgültigen Entfernung aller Daten muss ein:e Kollege:In zustimmen.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table  >
						<TableRow>
							<TableCell variant="head">Vorgemerkt von</TableCell>
							<TableCell>{residenceMarkedForDeletion?.modifyingUser}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Erläuterung</TableCell>
							<TableCell>{residenceMarkedForDeletion?.comment}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant="head">Bestätigung</TableCell>
							<TableCell>
								<Tooltip title='Bestätige Löschung'>
									<Button
										disabled={!isDeleteButtonEnabled()}
										onClick={() => handleDecision(true)} >
										{< Check />}
									</Button>
								</Tooltip>
								<Tooltip title='Vormerkung rückgängig machen'>
									<Button
										onClick={() => handleDecision(false)} >
										{<Close color='primary' />}
									</Button>
								</Tooltip>
							</TableCell>
						</TableRow>
					</Table>
				</Grid>
			</Grid>
		)
	}

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<CardActionArea onClick={onClickCard}>
				<Grid container spacing={2} direction='row'>
					<Grid item xs={12}>
						<CardContent>
							<Typography
								align='center'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(residence.residenceIdentifier as string, 30)}
							</Typography>
						</CardContent>
					</Grid>
					<Grid item xs={12}>
						<Divider variant='middle'>
							{residence !== undefined ? (
								<Typography fontSize={12} color={'primary'} variant={'body2'}>
									{formatDate(residence?.lastChanged)}
								</Typography>
							) : (
								void 0
							)}
						</Divider>
					</Grid>
				</Grid>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}>
								{abbreviateString(residence?.description as string, 60)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Tooltip
							title={isAuthorizedTenantAdmin ? 'Wohnbereiche anzeigen/hinzufügen' : ''}>
							<span>
								<Button disabled={!isAuthorizedTenantAdmin} onClick={handleClickUnits}>
									<Chair color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'} />
								</Button>
							</span>
						</Tooltip>
					</CardActions>
				</Grid>
			</Grid>
			<CardActions disableSpacing>
				<ExpandMore
					disabled={!isAuthorizedTenantAdmin}
					expand={isExpanded}
					onClick={handleExpandClick}
					aria-expanded={isExpanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<CardContent >
					{
						isUndefined(residenceMarkedForDeletion) ? (
							isAuthorizedGlobalAdmin ? ImmediateDeletion() : DeletionReservation(deletionReservation)
						) :
							DeletionDecision()
					}				
				</CardContent>
			</Collapse>
		</Card>
	);
}
