import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Unit } from '../generated';
import UnitCard from './UnitCard';

interface UnitGridViewProps {
	unitList: Unit[];
	handleDisplayUnit: any;
	isAuthorizedGlobalAdmin: boolean;
	isAuthorizedTenantAdmin: boolean;
	handleClickMarkForDeletion: any;
	getMarkedForDeletion: any;
	handleClickDeletionDecision: any;
	handleClickDelete:any
}

export default function UnitGridView({
	unitList,
	handleDisplayUnit,
	isAuthorizedGlobalAdmin,
	isAuthorizedTenantAdmin,
	handleClickMarkForDeletion,
	getMarkedForDeletion,
	handleClickDeletionDecision,
	handleClickDelete
}: UnitGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{unitList?.length > 0 ? '' : 'Kein Wohnbereich hinterlegt.'}
			</Grid>
			{unitList.map((unit) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={unit.unitIdentifier}>
					<UnitCard
						unit={unit}
						onClickCard={() => handleDisplayUnit(unit)}
						isAuthorizedGlobalAdmin={isAuthorizedGlobalAdmin}
						isAuthorizedTenantAdmin={isAuthorizedTenantAdmin}
						handleClickMarkForDeletion={handleClickMarkForDeletion}
						getMarkedForDeletion={getMarkedForDeletion}
						handleClickDeletionDecision={handleClickDeletionDecision}
						handleClickDelete={() => handleClickDelete(unit)}
					/>
				</Grid>
			))}
		</Grid>
	);
}
