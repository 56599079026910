/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserUpdateParameters
 */
export interface UserUpdateParameters {
    /**
     * 
     * @type {User}
     * @memberof UserUpdateParameters
     */
    user?: User;
    /**
     * 
     * @type {Array<Group>}
     * @memberof UserUpdateParameters
     */
    groups?: Array<Group>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateParameters
     */
    initialPassword?: string;
}

/**
 * Check if a given object implements the UserUpdateParameters interface.
 */
export function instanceOfUserUpdateParameters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateParametersFromJSON(json: any): UserUpdateParameters {
    return UserUpdateParametersFromJSONTyped(json, false);
}

export function UserUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
        'initialPassword': !exists(json, 'initialPassword') ? undefined : json['initialPassword'],
    };
}

export function UserUpdateParametersToJSON(value?: UserUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
        'initialPassword': value.initialPassword,
    };
}

