import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import GroupsIcon from '@mui/icons-material/Groups';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GppBadIcon from '@mui/icons-material/GppBad';
import Delete from '@mui/icons-material/Delete';
import AddModeratorRoundedIcon from '@mui/icons-material/AddModeratorRounded';
import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Tooltip,
	Typography,
} from '@mui/material';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
	CertificateMetaData,
	MailclientConfig,
	Tenant,
} from '../generated';
import {
	abbreviateString,
	ExpandMore,
	formatDate,
	isAuthorizedForGlobalAdminRole,
	isAuthorizedForTenantAdminRole,
	isAuthorizedForUserMgmtRole
} from './format';

import { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';


interface TenantCardProps {
	tenant: Tenant;
	onClickCard: any;
	handleClickUsers: any;
	handleClickResidences: any;
	handleClickCertificate: any;
	handleClickCertificateRevocation: any;
	handleClickCertificateRenewal: any;
	loadCertificateMetaData: any;
	handleClickDelete: any;
	handleClickMailclientConfig: any;
	userRoleList: string[];
	refresh: number;
}

export default function TenantCard({
	tenant,
	onClickCard,
	handleClickUsers,
	handleClickResidences,
	handleClickCertificate,
	handleClickCertificateRevocation,
	handleClickCertificateRenewal,
	loadCertificateMetaData,
	handleClickDelete,
	handleClickMailclientConfig,
	userRoleList,
	refresh,
}: TenantCardProps) {
	let cardcolor = 'primary';

	const tenantIdentifier = tenant?.tenantIdentifier as string;
	const isAuthorizedGlobalAdmin: boolean = useMemo(() => {
		return isAuthorizedForGlobalAdminRole(userRoleList);
	}, [userRoleList]);

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const isAuthorizedUserMgmt: boolean = useMemo(() => {
		return isAuthorizedForUserMgmtRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);


	const [certificateMetaData, setCertificateMetaData] = useState({} as CertificateMetaData);

	useEffect(() => {
		const getCertificateMetaData = async () => {
			let promise = trackPromise(loadCertificateMetaData());
			setCertificateMetaData(await promise);
		};
		getCertificateMetaData();
	}, [refresh]);


	const isDownloadButtonEnabled = () => {
		return isAuthorizedTenantAdmin && Object.keys(certificateMetaData).length != 0
	}

	const isRevokeButtonEnabled = () => {
		return isAuthorizedGlobalAdmin && Object.keys(certificateMetaData).length != 0
	}

	const isRenewButtonEnabled = () => {
		return isAuthorizedGlobalAdmin && Object.keys(certificateMetaData).length === 0
	}

	const [isExpanded, setIsExpanded] = useState(false);

	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};


	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<CardActionArea onClick={onClickCard}>
				<Grid container spacing={2} direction='row'>
					<Grid item xs={12}>
						<CardContent>
							<Typography
								align='center'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(tenant.tenantIdentifier as string, 30)}
							</Typography>
						</CardContent>
					</Grid>
					<Grid item xs={12}>
						<Divider variant='middle'>
							{tenant !== undefined ? (
								<Typography fontSize={12} color={'primary'} variant={'body2'}>
									{formatDate(tenant?.lastChanged)}
								</Typography>
							) : (
								void 0
							)}
						</Divider>
					</Grid>
				</Grid>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}>
								{abbreviateString(tenant?.description as string, 90)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Tooltip
							title={isDownloadButtonEnabled() ? 'Zertifikat herunterladen' : ''}>
							<Button
								disabled={!isDownloadButtonEnabled()}
								color='primary'
								onClick={handleClickCertificate}>
								<WorkspacePremiumIcon
									color={isDownloadButtonEnabled() ? 'primary' : 'secondary'}
								/>
							</Button>
						</Tooltip>
						<Tooltip
							title={
								isAuthorizedTenantAdmin ? 'Wohnheime anzeigen/hinzufügen' : ''
							}>
							<Button
								disabled={!isAuthorizedTenantAdmin}
								onClick={handleClickResidences}>
								<OtherHousesIcon
									color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
								/>
							</Button>
						</Tooltip>
						<Tooltip
							title={isAuthorizedUserMgmt ? 'Nutzer anzeigen/hinzufügen' : ''}>
							<Button
								disabled={!isAuthorizedUserMgmt}
								onClick={handleClickUsers}>
								<GroupsIcon
									color={isAuthorizedUserMgmt ? 'primary' : 'secondary'}
								/>
							</Button>
						</Tooltip>
						<Tooltip
							title={isAuthorizedTenantAdmin ? 'Emailserver hinzufügen' : ''}>
							<span>
								<Button
									disabled={!isAuthorizedTenantAdmin}
									onClick={handleClickMailclientConfig}>
									<AlternateEmailIcon
										color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
									/>
								</Button>
							</span>
						</Tooltip>
					</CardActions>
				</Grid>
			</Grid>
			<CardActions disableSpacing>
				<ExpandMore
					disabled={!isAuthorizedGlobalAdmin}
					expand={isExpanded}
					onClick={handleExpandClick}
					aria-expanded={isExpanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<CardContent >
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography variant={'h6'}>
								Zertifikatemanagement
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant='contained'
								disabled={!isRevokeButtonEnabled()}
								color='primary'
								onClick={handleClickCertificateRevocation}>
								{<GppBadIcon />}Widerrufen
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant='contained'
								disabled={!isRenewButtonEnabled()}
								color='primary'
								onClick={handleClickCertificateRenewal}>
								{<AddModeratorRoundedIcon />}Neu erstellen
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={2} paddingTop={4} direction='row'>
						<Grid item xs={12}>
							<Typography variant='h6'>
								Löschen des Betreibers
							</Typography>
							<Typography>
								Diese Aktion entfernt endgültig alle Daten des Betreibers (Wohnheime, Wohnbereiche, Bewohner)
							</Typography>
						</Grid>
							<Grid item xs={12}>
							<Button
								variant='contained'
								disabled={!isAuthorizedGlobalAdmin}
								color='primary'
								onClick={handleClickDelete}>
								{<Delete />}Betreiber Löschen
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Collapse>
		</Card >
	);
}
