/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CertificateMetaData,
  Group,
  MailclientConfig,
  MailclientConfigInsertParameter,
  MailclientConfigUpdateParameter,
  Residence,
  ResidenceDeletionDecision,
  ResidenceDeletionReservationInsertParameters,
  ResidenceInsertParameter,
  ResidenceMarkedForDeletion,
  ResidenceUpdateParameter,
  Tenant,
  TenantDeletionDecision,
  TenantDeletionReservationInsertParameters,
  TenantInsertParameter,
  TenantMarkedForDeletion,
  TenantUpdateParameter,
  Unit,
  UnitDeletionDecision,
  UnitDeletionReservationInsertParameters,
  UnitInsertParameter,
  UnitMarkedForDeletion,
  UnitUpdateParameter,
  User,
  UserInsertParameters,
  UserUpdateParameters,
} from '../models';
import {
    CertificateMetaDataFromJSON,
    CertificateMetaDataToJSON,
    GroupFromJSON,
    GroupToJSON,
    MailclientConfigFromJSON,
    MailclientConfigToJSON,
    MailclientConfigInsertParameterFromJSON,
    MailclientConfigInsertParameterToJSON,
    MailclientConfigUpdateParameterFromJSON,
    MailclientConfigUpdateParameterToJSON,
    ResidenceFromJSON,
    ResidenceToJSON,
    ResidenceDeletionDecisionFromJSON,
    ResidenceDeletionDecisionToJSON,
    ResidenceDeletionReservationInsertParametersFromJSON,
    ResidenceDeletionReservationInsertParametersToJSON,
    ResidenceInsertParameterFromJSON,
    ResidenceInsertParameterToJSON,
    ResidenceMarkedForDeletionFromJSON,
    ResidenceMarkedForDeletionToJSON,
    ResidenceUpdateParameterFromJSON,
    ResidenceUpdateParameterToJSON,
    TenantFromJSON,
    TenantToJSON,
    TenantDeletionDecisionFromJSON,
    TenantDeletionDecisionToJSON,
    TenantDeletionReservationInsertParametersFromJSON,
    TenantDeletionReservationInsertParametersToJSON,
    TenantInsertParameterFromJSON,
    TenantInsertParameterToJSON,
    TenantMarkedForDeletionFromJSON,
    TenantMarkedForDeletionToJSON,
    TenantUpdateParameterFromJSON,
    TenantUpdateParameterToJSON,
    UnitFromJSON,
    UnitToJSON,
    UnitDeletionDecisionFromJSON,
    UnitDeletionDecisionToJSON,
    UnitDeletionReservationInsertParametersFromJSON,
    UnitDeletionReservationInsertParametersToJSON,
    UnitInsertParameterFromJSON,
    UnitInsertParameterToJSON,
    UnitMarkedForDeletionFromJSON,
    UnitMarkedForDeletionToJSON,
    UnitUpdateParameterFromJSON,
    UnitUpdateParameterToJSON,
    UserFromJSON,
    UserToJSON,
    UserInsertParametersFromJSON,
    UserInsertParametersToJSON,
    UserUpdateParametersFromJSON,
    UserUpdateParametersToJSON,
} from '../models';

export interface DeleteUserRequest {
    userId: string;
}

export interface ExecuteResidenceDeletionDecisionRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceDeletionReservationId: number;
    residenceDeletionDecision?: ResidenceDeletionDecision;
}

export interface ExecuteTenantDeletionDecisionRequest {
    tenantIdentifier: string;
    tenantDeletionReservationId: number;
    tenantDeletionDecision?: TenantDeletionDecision;
}

export interface ExecuteUnitDeletionDecisionRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    unitDeletionReservationId: number;
    unitDeletionDecision?: UnitDeletionDecision;
}

export interface GetCertificateRequest {
    tenantIdentifier: string;
}

export interface GetCertificateMetaDataRequest {
    tenantIdentifier: string;
}

export interface GetGroupsRequest {
    tenantIdentifier: string;
}

export interface GetMailclientConfigRequest {
    tenantIdentifier: string;
}

export interface GetResidencesRequest {
    tenantIdentifier: string;
}

export interface GetResidencesMarkedForDeletionRequest {
    tenantIdentifier: string;
}

export interface GetUnitsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetUnitsMarkedForDeletionRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetUserGroupsByIdRequest {
    userId: string;
}

export interface GetUsersRequest {
    tenantIdentifier: string;
}

export interface PostCertificateRequest {
    tenantIdentifier: string;
}

export interface PostCertificateRevocationRequest {
    tenantIdentifier: string;
}

export interface PostMailclientConfigRequest {
    tenantIdentifier: string;
    mailclientConfigInsertParameter?: MailclientConfigInsertParameter;
}

export interface PostResidenceRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceInsertParameter?: ResidenceInsertParameter;
}

export interface PostResidenceDeletionRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface PostResidenceDeletionReservationRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceDeletionReservationInsertParameters?: ResidenceDeletionReservationInsertParameters;
}

export interface PostTenantRequest {
    tenantIdentifier: string;
    tenantInsertParameter?: TenantInsertParameter;
}

export interface PostTenantDeletionRequest {
    tenantIdentifier: string;
}

export interface PostTenantDeletionReservationRequest {
    tenantIdentifier: string;
    tenantDeletionReservationInsertParameters?: TenantDeletionReservationInsertParameters;
}

export interface PostUnitRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    unitInsertParameter?: UnitInsertParameter;
}

export interface PostUnitDeletionRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
}

export interface PostUnitDeletionReservationRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    unitDeletionReservationInsertParameters?: UnitDeletionReservationInsertParameters;
}

export interface PostUserRequest {
    userInsertParameters?: UserInsertParameters;
}

export interface PostUserFileRequest {
    tenantIdentifier: string;
    file?: Blob;
}

export interface PostUsersRequest {
    userInsertParameters: Array<UserInsertParameters>;
}

export interface PutMailclientConfigRequest {
    isUpdatePasswordOnly: boolean;
    tenantIdentifier: string;
    mailclientConfigId: number;
    mailclientConfigUpdateParameter?: MailclientConfigUpdateParameter;
}

export interface PutResidenceRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceUpdateParameter?: ResidenceUpdateParameter;
}

export interface PutTenantRequest {
    tenantIdentifier: string;
    tenantUpdateParameter?: TenantUpdateParameter;
}

export interface PutUnitRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    unitUpdateParameter?: UnitUpdateParameter;
}

export interface PutUserRequest {
    userId: string;
    userUpdateParameters?: UserUpdateParameters;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Deletes user.
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user.
     */
    async deleteUser(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw({ userId: userId }, initOverrides);
    }

    /**
     * Decides on the deletion of a residence.
     */
    async executeResidenceDeletionDecisionRaw(requestParameters: ExecuteResidenceDeletionDecisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling executeResidenceDeletionDecision.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling executeResidenceDeletionDecision.');
        }

        if (requestParameters.residenceDeletionReservationId === null || requestParameters.residenceDeletionReservationId === undefined) {
            throw new runtime.RequiredError('residenceDeletionReservationId','Required parameter requestParameters.residenceDeletionReservationId was null or undefined when calling executeResidenceDeletionDecision.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/residence/{residenceDeletionReservationId}`.replace(`{${"residenceDeletionReservationId"}}`, encodeURIComponent(String(requestParameters.residenceDeletionReservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceDeletionDecisionToJSON(requestParameters.residenceDeletionDecision),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decides on the deletion of a residence.
     */
    async executeResidenceDeletionDecision(tenantIdentifier: string, residenceIdentifier: string, residenceDeletionReservationId: number, residenceDeletionDecision?: ResidenceDeletionDecision, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.executeResidenceDeletionDecisionRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceDeletionReservationId: residenceDeletionReservationId, residenceDeletionDecision: residenceDeletionDecision }, initOverrides);
    }

    /**
     * Decides on the deletion of a tenant.
     */
    async executeTenantDeletionDecisionRaw(requestParameters: ExecuteTenantDeletionDecisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling executeTenantDeletionDecision.');
        }

        if (requestParameters.tenantDeletionReservationId === null || requestParameters.tenantDeletionReservationId === undefined) {
            throw new runtime.RequiredError('tenantDeletionReservationId','Required parameter requestParameters.tenantDeletionReservationId was null or undefined when calling executeTenantDeletionDecision.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/tenant/{tenantDeletionReservationId}`.replace(`{${"tenantDeletionReservationId"}}`, encodeURIComponent(String(requestParameters.tenantDeletionReservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TenantDeletionDecisionToJSON(requestParameters.tenantDeletionDecision),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decides on the deletion of a tenant.
     */
    async executeTenantDeletionDecision(tenantIdentifier: string, tenantDeletionReservationId: number, tenantDeletionDecision?: TenantDeletionDecision, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.executeTenantDeletionDecisionRaw({ tenantIdentifier: tenantIdentifier, tenantDeletionReservationId: tenantDeletionReservationId, tenantDeletionDecision: tenantDeletionDecision }, initOverrides);
    }

    /**
     * Decides on the deletion of a unit.
     */
    async executeUnitDeletionDecisionRaw(requestParameters: ExecuteUnitDeletionDecisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling executeUnitDeletionDecision.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling executeUnitDeletionDecision.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling executeUnitDeletionDecision.');
        }

        if (requestParameters.unitDeletionReservationId === null || requestParameters.unitDeletionReservationId === undefined) {
            throw new runtime.RequiredError('unitDeletionReservationId','Required parameter requestParameters.unitDeletionReservationId was null or undefined when calling executeUnitDeletionDecision.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/unit/{unitDeletionReservationId}`.replace(`{${"unitDeletionReservationId"}}`, encodeURIComponent(String(requestParameters.unitDeletionReservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnitDeletionDecisionToJSON(requestParameters.unitDeletionDecision),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decides on the deletion of a unit.
     */
    async executeUnitDeletionDecision(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, unitDeletionReservationId: number, unitDeletionDecision?: UnitDeletionDecision, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.executeUnitDeletionDecisionRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, unitDeletionReservationId: unitDeletionReservationId, unitDeletionDecision: unitDeletionDecision }, initOverrides);
    }

    /**
     * Status of the API.
     * Status of the API.
     */
    async getApiStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Status of the API.
     * Status of the API.
     */
    async getApiStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApiStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get tenants for which the user is authorized.
     */
    async getAuthorizedTenantsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tenant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantFromJSON));
    }

    /**
     * Get tenants for which the user is authorized.
     */
    async getAuthorizedTenants(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tenant>> {
        const response = await this.getAuthorizedTenantsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Certificate Revocation List.
     * Certificate Revocation List.
     */
    async getCRLRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/crl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Certificate Revocation List.
     * Certificate Revocation List.
     */
    async getCRL(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getCRLRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get certificate file.
     * Get certificate file.
     */
    async getCertificateRaw(requestParameters: GetCertificateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getCertificate.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenantCertificates/certificate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get certificate file.
     * Get certificate file.
     */
    async getCertificate(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getCertificateRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get certificate meta data.
     * Get certificate meta data.
     */
    async getCertificateMetaDataRaw(requestParameters: GetCertificateMetaDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CertificateMetaData>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getCertificateMetaData.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenantCertificates/certificateMetaData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CertificateMetaDataFromJSON(jsonValue));
    }

    /**
     * Get certificate meta data.
     * Get certificate meta data.
     */
    async getCertificateMetaData(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CertificateMetaData> {
        const response = await this.getCertificateMetaDataRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get tenant groups.
     */
    async getGroupsRaw(requestParameters: GetGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Group>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupFromJSON));
    }

    /**
     * Get tenant groups.
     */
    async getGroups(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Group>> {
        const response = await this.getGroupsRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Mailclient configurations from Tenant.
     * Mailclient configurations from Tenant.
     */
    async getMailclientConfigRaw(requestParameters: GetMailclientConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailclientConfig>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getMailclientConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mailclientConfig/mailclientConfigs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailclientConfigFromJSON(jsonValue));
    }

    /**
     * Mailclient configurations from Tenant.
     * Mailclient configurations from Tenant.
     */
    async getMailclientConfig(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailclientConfig> {
        const response = await this.getMailclientConfigRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get residences for a specific tenant.
     */
    async getResidencesRaw(requestParameters: GetResidencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Residence>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidences.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceFromJSON));
    }

    /**
     * Get residences for a specific tenant.
     */
    async getResidences(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Residence>> {
        const response = await this.getResidencesRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Gets all residences marked for deletion.
     * Gets all residences marked for deletion.
     */
    async getResidencesMarkedForDeletionRaw(requestParameters: GetResidencesMarkedForDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResidenceMarkedForDeletion>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidencesMarkedForDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/deletionReservation/residences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceMarkedForDeletionFromJSON));
    }

    /**
     * Gets all residences marked for deletion.
     * Gets all residences marked for deletion.
     */
    async getResidencesMarkedForDeletion(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResidenceMarkedForDeletion>> {
        const response = await this.getResidencesMarkedForDeletionRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Gets all tenants marked for deletion.
     * Gets all tenants marked for deletion.
     */
    async getTenantsMarkedForDeletionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TenantMarkedForDeletion>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/deletionReservation/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantMarkedForDeletionFromJSON));
    }

    /**
     * Gets all tenants marked for deletion.
     * Gets all tenants marked for deletion.
     */
    async getTenantsMarkedForDeletion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TenantMarkedForDeletion>> {
        const response = await this.getTenantsMarkedForDeletionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get units for a specific tenant and residence.
     */
    async getUnitsRaw(requestParameters: GetUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Unit>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getUnits.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getUnits.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitFromJSON));
    }

    /**
     * Get units for a specific tenant and residence.
     */
    async getUnits(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Unit>> {
        const response = await this.getUnitsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Gets all units marked for deletion.
     * Gets all unit marked for deletion.
     */
    async getUnitsMarkedForDeletionRaw(requestParameters: GetUnitsMarkedForDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnitMarkedForDeletion>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getUnitsMarkedForDeletion.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getUnitsMarkedForDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/deletionReservation/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitMarkedForDeletionFromJSON));
    }

    /**
     * Gets all units marked for deletion.
     * Gets all unit marked for deletion.
     */
    async getUnitsMarkedForDeletion(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnitMarkedForDeletion>> {
        const response = await this.getUnitsMarkedForDeletionRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getUserEffectiveRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get user groups by Id.
     */
    async getUserGroupsByIdRaw(requestParameters: GetUserGroupsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Group>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserGroupsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupFromJSON));
    }

    /**
     * Get user groups by Id.
     */
    async getUserGroupsById(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Group>> {
        const response = await this.getUserGroupsByIdRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     * Get tenant users.
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/keycloak/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Get tenant users.
     */
    async getUsers(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.getUsersRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Generate a tenant certificate.
     * Generate a tenant certificate
     */
    async postCertificateRaw(requestParameters: PostCertificateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postCertificate.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenantCertificates/certificate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate a tenant certificate.
     * Generate a tenant certificate
     */
    async postCertificate(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCertificateRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
    }

    /**
     * Revoke a certificate.
     * Revoke a certificate
     */
    async postCertificateRevocationRaw(requestParameters: PostCertificateRevocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postCertificateRevocation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenantCertificates/revocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke a certificate.
     * Revoke a certificate
     */
    async postCertificateRevocation(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCertificateRevocationRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
    }

    /**
     * Creates a new MailclientConfig and its required configuration. Returns the newly generated \'mailclientConfigId\' as a Long if successful.
     * Create a new MailclientConfig and its required configuration.
     */
    async postMailclientConfigRaw(requestParameters: PostMailclientConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postMailclientConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mailclientConfig/mailclientConfigs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailclientConfigInsertParameterToJSON(requestParameters.mailclientConfigInsertParameter),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new MailclientConfig and its required configuration. Returns the newly generated \'mailclientConfigId\' as a Long if successful.
     * Create a new MailclientConfig and its required configuration.
     */
    async postMailclientConfig(tenantIdentifier: string, mailclientConfigInsertParameter?: MailclientConfigInsertParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.postMailclientConfigRaw({ tenantIdentifier: tenantIdentifier, mailclientConfigInsertParameter: mailclientConfigInsertParameter }, initOverrides);
        return await response.value();
    }

    /**
     * Create a new residence and its required configuration.
     * Create a new residence and its required configuration.
     */
    async postResidenceRaw(requestParameters: PostResidenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidence.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidence.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceInsertParameterToJSON(requestParameters.residenceInsertParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new residence and its required configuration.
     * Create a new residence and its required configuration.
     */
    async postResidence(tenantIdentifier: string, residenceIdentifier: string, residenceInsertParameter?: ResidenceInsertParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postResidenceRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceInsertParameter: residenceInsertParameter }, initOverrides);
    }

    /**
     * Deletes a residence from the database.
     * Deletes a residence from the database.
     */
    async postResidenceDeletionRaw(requestParameters: PostResidenceDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceDeletion.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/residence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a residence from the database.
     * Deletes a residence from the database.
     */
    async postResidenceDeletion(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postResidenceDeletionRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
    }

    /**
     * Creates a request for deletion of a residence in the database.
     * Creates a request for deletion of a residence in the database.
     */
    async postResidenceDeletionReservationRaw(requestParameters: PostResidenceDeletionReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceDeletionReservation.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceDeletionReservation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/residences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceDeletionReservationInsertParametersToJSON(requestParameters.residenceDeletionReservationInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a request for deletion of a residence in the database.
     * Creates a request for deletion of a residence in the database.
     */
    async postResidenceDeletionReservation(tenantIdentifier: string, residenceIdentifier: string, residenceDeletionReservationInsertParameters?: ResidenceDeletionReservationInsertParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.postResidenceDeletionReservationRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceDeletionReservationInsertParameters: residenceDeletionReservationInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a new tenant and its required configuration.
     * Create a new tenant and its required configuration.
     */
    async postTenantRaw(requestParameters: PostTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postTenant.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantInsertParameterToJSON(requestParameters.tenantInsertParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new tenant and its required configuration.
     * Create a new tenant and its required configuration.
     */
    async postTenant(tenantIdentifier: string, tenantInsertParameter?: TenantInsertParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postTenantRaw({ tenantIdentifier: tenantIdentifier, tenantInsertParameter: tenantInsertParameter }, initOverrides);
    }

    /**
     * Deletes a tenant from the database.
     * Deletes a tenant from the database.
     */
    async postTenantDeletionRaw(requestParameters: PostTenantDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postTenantDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a tenant from the database.
     * Deletes a tenant from the database.
     */
    async postTenantDeletion(tenantIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postTenantDeletionRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
    }

    /**
     * Creates a request for deletion of a tenant in the database.
     * Creates a request for deletion of a tenant in the database.
     */
    async postTenantDeletionReservationRaw(requestParameters: PostTenantDeletionReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postTenantDeletionReservation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantDeletionReservationInsertParametersToJSON(requestParameters.tenantDeletionReservationInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a request for deletion of a tenant in the database.
     * Creates a request for deletion of a tenant in the database.
     */
    async postTenantDeletionReservation(tenantIdentifier: string, tenantDeletionReservationInsertParameters?: TenantDeletionReservationInsertParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.postTenantDeletionReservationRaw({ tenantIdentifier: tenantIdentifier, tenantDeletionReservationInsertParameters: tenantDeletionReservationInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a new unit and its required configuration.
     * Create a new unit and its required configuration.
     */
    async postUnitRaw(requestParameters: PostUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postUnit.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postUnit.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postUnit.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/units`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnitInsertParameterToJSON(requestParameters.unitInsertParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new unit and its required configuration.
     * Create a new unit and its required configuration.
     */
    async postUnit(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, unitInsertParameter?: UnitInsertParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUnitRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, unitInsertParameter: unitInsertParameter }, initOverrides);
    }

    /**
     * Deletes a unit from the database.
     * Deletes a unit from the database.
     */
    async postUnitDeletionRaw(requestParameters: PostUnitDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postUnitDeletion.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postUnitDeletion.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postUnitDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deletion/unit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a unit from the database.
     * Deletes a unit from the database.
     */
    async postUnitDeletion(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUnitDeletionRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier }, initOverrides);
    }

    /**
     * Creates a request for deletion of a unit in the database.
     * Creates a request for deletion of a unit in the database.
     */
    async postUnitDeletionReservationRaw(requestParameters: PostUnitDeletionReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postUnitDeletionReservation.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postUnitDeletionReservation.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postUnitDeletionReservation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deletion/deletionReservation/units`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnitDeletionReservationInsertParametersToJSON(requestParameters.unitDeletionReservationInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a request for deletion of a unit in the database.
     * Creates a request for deletion of a unit in the database.
     */
    async postUnitDeletionReservation(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, unitDeletionReservationInsertParameters?: UnitDeletionReservationInsertParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.postUnitDeletionReservationRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, unitDeletionReservationInsertParameters: unitDeletionReservationInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a user.
     * Create a user
     */
    async postUserRaw(requestParameters: PostUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/keycloak/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInsertParametersToJSON(requestParameters.userInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a user.
     * Create a user
     */
    async postUser(userInsertParameters?: UserInsertParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.postUserRaw({ userInsertParameters: userInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Parse multiple users from batch file.
     * Parse multiple users from batch file.
     */
    async postUserFileRaw(requestParameters: PostUserFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postUserFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/userData/file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Parse multiple users from batch file.
     * Parse multiple users from batch file.
     */
    async postUserFile(tenantIdentifier: string, file?: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.postUserFileRaw({ tenantIdentifier: tenantIdentifier, file: file }, initOverrides);
        return await response.value();
    }

    /**
     * Create multiple users
     * Create multiple users
     */
    async postUsersRaw(requestParameters: PostUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userInsertParameters === null || requestParameters.userInsertParameters === undefined) {
            throw new runtime.RequiredError('userInsertParameters','Required parameter requestParameters.userInsertParameters was null or undefined when calling postUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/keycloak/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userInsertParameters.map(UserInsertParametersToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create multiple users
     * Create multiple users
     */
    async postUsers(userInsertParameters: Array<UserInsertParameters>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersRaw({ userInsertParameters: userInsertParameters }, initOverrides);
    }

    /**
     * Update an existing MailclientConfig.
     * Update an existing MailclientConfig.
     */
    async putMailclientConfigRaw(requestParameters: PutMailclientConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.isUpdatePasswordOnly === null || requestParameters.isUpdatePasswordOnly === undefined) {
            throw new runtime.RequiredError('isUpdatePasswordOnly','Required parameter requestParameters.isUpdatePasswordOnly was null or undefined when calling putMailclientConfig.');
        }

        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putMailclientConfig.');
        }

        if (requestParameters.mailclientConfigId === null || requestParameters.mailclientConfigId === undefined) {
            throw new runtime.RequiredError('mailclientConfigId','Required parameter requestParameters.mailclientConfigId was null or undefined when calling putMailclientConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.isUpdatePasswordOnly !== undefined) {
            queryParameters['IsUpdatePasswordOnly'] = requestParameters.isUpdatePasswordOnly;
        }

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mailclientConfig/mailclientConfigs{mailclientConfigId}`.replace(`{${"mailclientConfigId"}}`, encodeURIComponent(String(requestParameters.mailclientConfigId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailclientConfigUpdateParameterToJSON(requestParameters.mailclientConfigUpdateParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing MailclientConfig.
     * Update an existing MailclientConfig.
     */
    async putMailclientConfig(isUpdatePasswordOnly: boolean, tenantIdentifier: string, mailclientConfigId: number, mailclientConfigUpdateParameter?: MailclientConfigUpdateParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putMailclientConfigRaw({ isUpdatePasswordOnly: isUpdatePasswordOnly, tenantIdentifier: tenantIdentifier, mailclientConfigId: mailclientConfigId, mailclientConfigUpdateParameter: mailclientConfigUpdateParameter }, initOverrides);
    }

    /**
     * Update an existing residence.
     * Update an existing residence.
     */
    async putResidenceRaw(requestParameters: PutResidenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidence.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidence.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceUpdateParameterToJSON(requestParameters.residenceUpdateParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing residence.
     * Update an existing residence.
     */
    async putResidence(tenantIdentifier: string, residenceIdentifier: string, residenceUpdateParameter?: ResidenceUpdateParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putResidenceRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceUpdateParameter: residenceUpdateParameter }, initOverrides);
    }

    /**
     * Update an existing tenant.
     * Update an existing tenant.
     */
    async putTenantRaw(requestParameters: PutTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putTenant.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tenants`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TenantUpdateParameterToJSON(requestParameters.tenantUpdateParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing tenant.
     * Update an existing tenant.
     */
    async putTenant(tenantIdentifier: string, tenantUpdateParameter?: TenantUpdateParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTenantRaw({ tenantIdentifier: tenantIdentifier, tenantUpdateParameter: tenantUpdateParameter }, initOverrides);
    }

    /**
     * Update an existing unit.
     * Update an existing unit.
     */
    async putUnitRaw(requestParameters: PutUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putUnit.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putUnit.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putUnit.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/units`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnitUpdateParameterToJSON(requestParameters.unitUpdateParameter),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing unit.
     * Update an existing unit.
     */
    async putUnit(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, unitUpdateParameter?: UnitUpdateParameter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putUnitRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, unitUpdateParameter: unitUpdateParameter }, initOverrides);
    }

    /**
     * Updates a user
     * Updates a user.
     */
    async putUserRaw(requestParameters: PutUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/keycloak/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateParametersToJSON(requestParameters.userUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a user
     * Updates a user.
     */
    async putUser(userId: string, userUpdateParameters?: UserUpdateParameters, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putUserRaw({ userId: userId, userUpdateParameters: userUpdateParameters }, initOverrides);
    }

}
