import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import { getNetworkApi } from '../components/keycloak';
import { ExecuteResidenceDeletionDecisionRequest, PostResidenceDeletionReservationRequest, Residence, ResidenceMarkedForDeletion } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import React from 'react';
import {
	isAuthorizedForGlobalAdminRole,
	isAuthorizedForTenantAdminRole,
	TenantData,
} from '../components/format';
import { useHistory } from 'react-router-dom';
import ResidenceTableView from '../components/ResidenceTableView';
import ResidenceGridView from '../components/ResidenceGridView';
import ResidenceForm from '../components/ResidenceForm';
import Confirm from '../components/Confirm';

export default function Residences(props: any) {
	const tenantData: TenantData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: '',
	};

	const [userRoleList, setUserRoleList] = React.useState([] as string[]);

	let history = useHistory();

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [isNewResidence, setIsNewResidence] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [displayResidence, setDisplayResidence] = useState(false);
	const [showResidenceForm, setShowResidenceForm] = useState(false);

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [selectedResidence, setSelectedResidence] = useState({} as Residence);
	const [residenceList, setResidenceList] = useState([] as Residence[]);

	const tenantIdentifier = tenantData.tenantIdentifier;

		const [showConfirmMarkForDeletion, setShowConfirmMarkForDeletion] = useState(false);
	const [showConfirmDeletionDecision, setShowConfirmDeletionDecision] = useState(false);
	const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
	const [deleteText, setDeleteText] = useState('');
	const [deletionReservation, setDeletionReservation] = useState({} as PostResidenceDeletionReservationRequest);
	const [markedForDeletionList, setMarkedForDeletionList] = useState([] as ResidenceMarkedForDeletion[]);
	const [deletionDecision, setDeletionDecision] = useState({} as ExecuteResidenceDeletionDecisionRequest);

	const [selectedResidenceForDeletion, setSelectedResidenceForDeletion] = useState({} as Residence);

	const isAuthorizedGlobalAdmin: boolean = useMemo(() => {
		return isAuthorizedForGlobalAdminRole(userRoleList);
	}, [userRoleList]);

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const forwardToUnits = useCallback(
		(residence: Residence) => {
			history.push({
				pathname:
					'/tenantId=' +
					residence.tenantIdentifier +
					'/residences/residenceId=' +
					residence.residenceIdentifier +
					'/units',
			});
		},
		[history]
	);

	useEffect(() => {
		const loadResidences = async () => {
			const api = getNetworkApi();
			if (tenantData) {
				try {
					const result = await api.getResidences(
						tenantData.tenantIdentifier as string
					);
					setResidenceList(result);
				} catch (error: any) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Wohnheime konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Wohnheime konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidences());
	}, [selectedResidence, refresh, showSuccessAlert]);

		useEffect(() => {
		const loadMarkedForDeletionList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidencesMarkedForDeletion(tenantData.tenantIdentifier);
				setMarkedForDeletionList(result);

			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the Marked for Deletion data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the Marked for Deletion data!'
					);
				}
			}
		};
		trackPromise(loadMarkedForDeletionList());
	}, [showSuccessAlert, refresh]);

	const handleClickOpenResidenceForm = (
		isNewResidence: boolean,
		residence: Residence
	) => {
		setSelectedResidence({
			tenantIdentifier: tenantData.tenantIdentifier,
		} as Residence);
		if (!isNewResidence) {
			setSelectedResidence(residence);
		}
		setIsNewResidence(isNewResidence);
		setShowResidenceForm(true);
		setDisplayResidence(false);
	};

	const handleDisplayResidence = (residence: Residence) => {
		setDisplayResidence(true);
		setShowResidenceForm(true);
		setSelectedResidence(residence);
		setIsNewResidence(false);
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const [isTableViewSelected, setIsTableViewSelected] = React.useState(false);
	const handleSwitchChange = () => {
		setIsTableViewSelected(!isTableViewSelected);
	};


	const handleClickMarkForDeletion = (deletionReservation: PostResidenceDeletionReservationRequest) => {
		setShowConfirmMarkForDeletion(true);
		setDeleteText('Wollen Sie wirklich das Wohnheim ' + deletionReservation.residenceIdentifier + ' zum Löschen vormerken?');
		setDeletionReservation(deletionReservation);
	};

	const markForDeletion = async () => {
			const client = getNetworkApi();
			try {
				await client.postResidenceDeletionReservation(
					deletionReservation.tenantIdentifier,
					deletionReservation.residenceIdentifier,
					deletionReservation.residenceDeletionReservationInsertParameters);
				setAlertText(
					'Wohnheim ' + deletionReservation.residenceIdentifier + ' wurde erfolgreich markiert zur endgültigen Löschung.'
				);

				setShowConfirmMarkForDeletion(false);
				setDeletionReservation({} as PostResidenceDeletionReservationRequest);
				setShowSuccessAlert(true);
			} catch (error) {
				setAlertText('Wohnheim wurde nicht markiert zur endgültigen Löschung');
				setShowErrorAlert(true);
			}

	};

	const getMarkedForDeletion = (residence: Residence): ResidenceMarkedForDeletion | undefined => {
		let filteredList: ResidenceMarkedForDeletion[] =
			markedForDeletionList.filter(function (el) {
				return el.tenantIdentifier === residence.tenantIdentifier &&
					el.residenceIdentifier === residence.residenceIdentifier
			});
		if (filteredList.length === 1) {
			return filteredList.at(0);
		} else {
			return undefined;
		}

	}

	const handleClickDeletionDecision = (
		deletionReservationDecision: ExecuteResidenceDeletionDecisionRequest,
	) => {
		setShowConfirmDeletionDecision(true);
		deletionReservationDecision.residenceDeletionDecision?.isPerformDeletion
			? setDeleteText(
				'Wollen Sie wirklich das Wohnheim ' +
				deletionReservationDecision.residenceIdentifier +
				' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.'
			)
			: setDeleteText(
				'Wollen Sie wirklich die Löschung für das Wohnheim ' +
				deletionReservationDecision.residenceIdentifier +
				' rückgängig machen?'
			);

		setDeletionDecision(deletionReservationDecision)
	};

	const finalDeletionDecision = async () => {
		const client = getNetworkApi();
		try {
			await client.executeResidenceDeletionDecision(
				deletionDecision.tenantIdentifier,
				deletionDecision.residenceIdentifier,
				deletionDecision.residenceDeletionReservationId,
				deletionDecision.residenceDeletionDecision
			);

			setShowSuccessAlert(true);
			setShowConfirmDeletionDecision(false);
			deletionDecision.residenceDeletionDecision?.isPerformDeletion
				? setAlertText('Wohnheim wurde erfolgreich gelöscht')
				: setAlertText('Löschvermerk für das Wohnheim wurde zurückgenommen');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Wohnheim konnte nicht gelöscht werden');
			console.log('There was an error while deleting!');
		}
	};

	const handleClickDelete = (residence: Residence) => {
		setShowConfirmDeletion(true);
		setDeleteText('Wollen Sie wirklich das Wohnheim ' + residence.residenceIdentifier + ' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.');
		setSelectedResidenceForDeletion(residence);
	};

	const deleteResidence = async () => {
		const client = getNetworkApi();
		try {
			await client.postResidenceDeletion(
				selectedResidenceForDeletion.tenantIdentifier as string,
				selectedResidenceForDeletion.residenceIdentifier as string
			);
			setAlertText(
				'Wohnheim ' + selectedResidenceForDeletion.residenceIdentifier + ' wurde erfolgreich endgültig gelöscht.'
			);

			setShowConfirmDeletion(false);
			setSelectedResidenceForDeletion({} as Residence);
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Betreiber wurde nicht gelöscht.');
			setShowErrorAlert(true);
			setShowConfirmDeletion(false);
		}
	};


	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2, marginBottom: 5 }}>
					<Button
						disabled={!isAuthorizedTenantAdmin}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenResidenceForm(true, {})}>
						{<Add />} Wohnheim
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip title={isTableViewSelected ? 'Kärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{isTableViewSelected ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					{isTableViewSelected ? (
						<ResidenceTableView
							residenceList={residenceList}
							isAuthorizedTenantAdmin={isAuthorizedTenantAdmin}
							handleClickOpenResidenceForm={handleClickOpenResidenceForm}
							handleClickUnits={forwardToUnits}
						/>
					) : (
						<ResidenceGridView
							residenceList={residenceList}
								handleDisplayResidence={handleDisplayResidence}
							isAuthorizedGlobalAdmin={isAuthorizedGlobalAdmin}
							isAuthorizedTenantAdmin={isAuthorizedTenantAdmin}
							handleClickUnits={forwardToUnits}
							handleClickMarkForDeletion={handleClickMarkForDeletion}
							getMarkedForDeletion={getMarkedForDeletion}
							handleClickDeletionDecision={handleClickDeletionDecision}
							handleClickDelete={handleClickDelete}
						/>
					)}
				</Grid>
			</Grid>
			{showResidenceForm && (
				<ResidenceForm
					open={showResidenceForm}
					setOpen={setShowResidenceForm}
					tenantIdentifier={tenantData.tenantIdentifier}
					isNewResidence={isNewResidence}
					displayResidence={displayResidence}
					selectedResidence={selectedResidence}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					residenceList={residenceList}
				/>
			)}
			{showConfirmMarkForDeletion && (
				<Confirm
					open={showConfirmMarkForDeletion}
					setOpen={setShowConfirmMarkForDeletion}
					text={deleteText}
					executefct={markForDeletion}
				/>
			)}
			{showConfirmDeletionDecision && (
				<Confirm
					open={showConfirmDeletionDecision}
					setOpen={setShowConfirmDeletionDecision}
					text={deleteText}
					executefct={finalDeletionDecision}
				/>
			)}
			{showConfirmDeletion && (
				<Confirm
					open={showConfirmDeletion}
					setOpen={setShowConfirmDeletion}
					text={deleteText}
					executefct={deleteResidence}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
