import React, { useState } from 'react';
import { Close, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	FormControlLabel,
	InputAdornment,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
	IconButton,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import {
		Tenant,
	MailclientConfig,
	MailclientConfigInsertParameter,
	MailclientConfigUpdateParameter,
} from '../generated';
import { isEmptyField } from './format';

interface MailclientConfigFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedMailclientConfig: MailclientConfig;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	selectedMailclientTenant: Tenant;
	isNewMailclientConfig: boolean;
}

export default function MailclientConfigForm({
		open,
		setOpen,
		selectedMailclientConfig,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		selectedMailclientTenant,
		isNewMailclientConfig,
	}: MailclientConfigFormProp) {

		const [mailclientConfig, setMailclientConfig] = useState({
			...selectedMailclientConfig,
			isOAUTH2Enabled: isNewMailclientConfig ? false : selectedMailclientConfig.isOAUTH2Enabled,
			isSSLEnabled: isNewMailclientConfig ? false : selectedMailclientConfig.isSSLEnabled,
			isStartTLSEnabled: isNewMailclientConfig ? false : selectedMailclientConfig.isStartTLSEnabled
		  } as MailclientConfig);
		  

	const handleClose = () => {
		setOpen(false);
	};

	const [showPassword, setShowPassword] = useState(false);
	const [showClientSecret, setShowClientSecret] = useState(false);
	const [showRefreshToken, setShowRefreshToken] = useState(false);

	const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
	};
	
	const handleToggleClientSecretVisibility = () => {
    setShowClientSecret(!showClientSecret);
	};
	
	const handleToggleRefreshTokenVisibility = () => {
    setShowRefreshToken(!showRefreshToken);
  };

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setMailclientConfig({
			...mailclientConfig,
			[name]: value,
		});
	};

	const handleInputChangeBoolean = (e: any) => {
		const { name, checked } = e.target;
		setMailclientConfig({
			...mailclientConfig,
			[name]: checked,
		});
	};
	  


	const saveMailclientConfig = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let mailclientConfigInsertParameter: MailclientConfigInsertParameter = {
			tenantIdentifier: mailclientConfig.tenantIdentifier,
            host: mailclientConfig.host,
            port: mailclientConfig.port,
            protocol: mailclientConfig.protocol,
            folder: mailclientConfig.folder,
            isStartTLSEnabled: mailclientConfig.isStartTLSEnabled,
            isSSLEnabled: mailclientConfig.isSSLEnabled,
            isOAUTH2Enabled: mailclientConfig.isOAUTH2Enabled,
            username: mailclientConfig.username,
            password: "prePassword",
            authMechanism: mailclientConfig.authMechanism,
            clientId: mailclientConfig.clientId,
            clientSecret: mailclientConfig.isOAUTH2Enabled ? "preClientSecret" : mailclientConfig.clientSecret,
            refreshToken: mailclientConfig.isOAUTH2Enabled ? "preRefreshToken" : mailclientConfig.refreshToken
		};
		let mailclientConfigUpdateParameter: MailclientConfigUpdateParameter = {
            host: undefined,
            port: undefined,
            protocol: undefined,
            folder: undefined,
            isStartTLSEnabled: undefined,
            isSSLEnabled: undefined,
            isOAUTH2Enabled: undefined,
            username: undefined,
            password: mailclientConfig.password,
            authMechanism: undefined,
            clientId: undefined,
            clientSecret: mailclientConfig.clientSecret,
            refreshToken: mailclientConfig.refreshToken
		};
		try {
			const configid = await client.postMailclientConfig(
				selectedMailclientTenant.tenantIdentifier as string,
				mailclientConfigInsertParameter
			);
			await client.putMailclientConfig(
				true,
				selectedMailclientTenant.tenantIdentifier as string,
				configid,
				mailclientConfigUpdateParameter
			);
			setAlertText('Informationen wurden erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Informationen wurden nicht gespeichert');
			setShowErrorAlert(true);
		}
	};


	const updateMailclientConfig = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let mailclientConfigUpdateParameter: MailclientConfigUpdateParameter = {
            host: mailclientConfig.host,
            port: mailclientConfig.port,
            protocol: mailclientConfig.protocol,
            folder: mailclientConfig.folder,
            isStartTLSEnabled: mailclientConfig.isStartTLSEnabled,
            isSSLEnabled: mailclientConfig.isSSLEnabled,
            isOAUTH2Enabled: mailclientConfig.isOAUTH2Enabled,
            username: mailclientConfig.username,
            password: "prePassword",
            authMechanism: mailclientConfig.authMechanism,
            clientId: mailclientConfig.clientId,
            clientSecret: mailclientConfig.isOAUTH2Enabled ? "preClientSecret" : mailclientConfig.clientSecret,
            refreshToken: mailclientConfig.isOAUTH2Enabled ? "preRefreshToken" : mailclientConfig.refreshToken
		};
		let mailclientConfigPasswordUpdateParameter: MailclientConfigUpdateParameter = {
            host: undefined,
            port: undefined,
            protocol: undefined,
            folder: undefined,
            isStartTLSEnabled: undefined,
            isSSLEnabled: undefined,
            isOAUTH2Enabled: undefined,
            username: undefined,
            password: mailclientConfig.password,
            authMechanism: undefined,
            clientId: undefined,
            clientSecret: mailclientConfig.clientSecret,
            refreshToken: mailclientConfig.refreshToken
		};
		if (!(selectedMailclientConfig.mailclientConfigId==undefined)){
		try {
			await client.putMailclientConfig(
				false,
				selectedMailclientTenant.tenantIdentifier as string,
				selectedMailclientConfig.mailclientConfigId,
				mailclientConfigUpdateParameter
			);
			await client.putMailclientConfig(
				true,
				selectedMailclientTenant.tenantIdentifier as string,
				selectedMailclientConfig.mailclientConfigId,
				mailclientConfigPasswordUpdateParameter
			);
			setAlertText('Informationen wurden erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Informationen wurden nicht geändert');
			setShowErrorAlert(true);
		}
		}else{
			setAlertText('Kein passendes Objekt gefunden, bei dem Informationen geändert werden können');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (mailclientConfig: MailclientConfig) => {
		return isEmptyField(mailclientConfig?.host) ||
		isEmptyField(mailclientConfig?.port) ||
		isEmptyField(mailclientConfig?.protocol) ||
		isEmptyField(mailclientConfig?.password) ||
		isEmptyField(mailclientConfig?.username) ||
		isEmptyField(mailclientConfig?.folder) ||
		isEmptyField(mailclientConfig?.isSSLEnabled) ||
		isEmptyField(mailclientConfig?.isStartTLSEnabled) ||
		isEmptyField(mailclientConfig?.isOAUTH2Enabled)

	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label="Email"
								placeholder={mailclientConfig?.username}
								name='username'
								id='outlined-size-normal'
								value={mailclientConfig?.username}
								onChange={handleInputChange}
								inputProps={{ maxLength: 255 }}
								required
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Passwort'
								name='password'
								placeholder={isNewMailclientConfig ? '' : 'Neues Passwort eingeben...'}
								id='outlined-size-normal'
								value={mailclientConfig?.password}
								onChange={handleInputChange}
								inputProps={{ maxLength: 255 }}
								required
								type={showPassword ? 'text' : 'password'}
								InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
									<IconButton onClick={handleTogglePasswordVisibility}>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
									</InputAdornment>
								)
								}}
							/>
						</Grid>
                        <Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Port'
								name='port'
								id='outlined-size-normal'
								value={mailclientConfig?.port}
								onChange={handleInputChange}
								inputProps={{ maxLength: 10 }}
								required
							/>
						</Grid>
                        <Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Folder'
								name='folder'
								id='outlined-size-normal'
								value={mailclientConfig?.folder}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
							/>
						</Grid>
                        <Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Host'
								name='host'
								id='outlined-size-normal'
								value={mailclientConfig?.host}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
							/>
						</Grid>
                        <Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Protocol'
								name='protocol'
								id='outlined-size-normal'
								value={mailclientConfig?.protocol}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<FormControlLabel
							control={
								<Checkbox
								checked={mailclientConfig.isSSLEnabled ?? false}
								onChange={handleInputChangeBoolean}
								name="isSSLEnabled"
								color="primary"
								required
								/>
							}
							label="Is SSL Enabled?"
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<FormControlLabel
							control={
								<Checkbox
								checked={mailclientConfig.isStartTLSEnabled ?? false}
								onChange={handleInputChangeBoolean}
								name="isStartTLSEnabled"
								color="primary"
								required
								/>
							}
							label="Is StartTLS Enabled?"
							/>
						</Grid>
						 <Grid item xs={6} marginTop={1}>
							<FormControlLabel
							control={
								<Checkbox
								checked={mailclientConfig.isOAUTH2Enabled ?? false}
								onChange={handleInputChangeBoolean}
								name="isOAUTH2Enabled"
								color="primary"
								required
								/>
							}
							label="Is OAUTH2 Enabled?"
							/>
						</Grid>
                        {mailclientConfig.isOAUTH2Enabled && (
							<>	
							<Grid item xs={6} marginTop={1}></Grid>
							<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='ClientId'
								name='clientId'
								id='outlined-size-normal'
								value={mailclientConfig?.clientId}
								onChange={handleInputChange}
								inputProps={{ maxLength: 255 }}
							/>
							</Grid>
							<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='AuthMechanism'
								name='authMechanism'
								id='outlined-size-normal'
								value={mailclientConfig?.authMechanism}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
							/>
							</Grid>
							<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='ClientSecret'
								name='clientSecret'
								id='outlined-size-normal'
								value={mailclientConfig?.clientSecret}
								onChange={handleInputChange}
								inputProps={{ maxLength: 255 }}
								type={showClientSecret ? 'text' : 'password'}
								InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
									<IconButton onClick={handleToggleClientSecretVisibility}>
										{showClientSecret ? <Visibility /> : <VisibilityOff />}
									</IconButton>
									</InputAdornment>
								)
								}}
							/>
							</Grid>
							<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='RefreshToken'
								name='refreshToken'
								id='outlined-size-normal'
								value={mailclientConfig?.refreshToken}
								onChange={handleInputChange}
								inputProps={{ maxLength: 255 }}
								type={showRefreshToken ? 'text' : 'password'}
								InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
									<IconButton onClick={handleToggleRefreshTokenVisibility}>
										{showRefreshToken ? <Visibility /> : <VisibilityOff />}
									</IconButton>
									</InputAdornment>
								)
								}}
							/>
							</Grid>
						</>
						)}
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} {'Abbrechen'}
									</Button>
								</Grid>
								{ (
									<Grid item>
										<Button
											variant='contained'
											disabled={isSaveButtonEnabled(mailclientConfig)}
											onClick={isNewMailclientConfig ? saveMailclientConfig : updateMailclientConfig}>
											{<Save />} Speichern
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
