import React, { useState } from 'react';
import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import { formatDate, isEmptyField, isInputKeycloakConform } from './format';
import { Unit, UnitInsertParameter, UnitUpdateParameter } from '../generated';

interface UnitFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tenantIdentifier: string;
	residenceIdentifier: string;
	isNewUnit: boolean;
	displayUnit: boolean;
	selectedUnit: Unit;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	unitList: Unit[];
}

export default function UnitForm(prop: UnitFormProp) {
	const {
		open,
		setOpen,
		isNewUnit,
		displayUnit,
		selectedUnit,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		unitList,
	} = prop;

	const [unit, setUnit] = useState(selectedUnit);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setUnit({
			...unit,
			[name]: value,
		});
	};

	const saveUnit = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let unitInsertParameter: UnitInsertParameter = {
			description: unit.description,
		};
		try {
			await client.postUnit(
				unit.tenantIdentifier as string,
				unit.residenceIdentifier as string,
				unit.unitIdentifier as string,
				unitInsertParameter
			);
			setAlertText('Wohnbereich wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Wohnbereich wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateUnit = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let unitUpdateParameter: UnitUpdateParameter = {
			description: unit.description,
		};
		try {
			await client.putUnit(
				unit.tenantIdentifier as string,
				unit.residenceIdentifier as string,
				unit.unitIdentifier as string,
				unitUpdateParameter
			);
			setAlertText('Wohnbereich wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Wohnbereiche wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (unit: Unit) => {
		return isNewUnit
			? !isEmptyField(unit?.unitIdentifier) &&
					!isEmptyField(unit?.description) &&
					!isUnitIdAlreadyAssigned(unit) &&
					!isInputKeycloakConform(unit.unitIdentifier)
			: !isEmptyField(unit?.unitIdentifier) && !isEmptyField(unit?.description);
	};

	const isUnitIdAlreadyAssigned = (unit: Unit) => {
		if (unit?.unitIdentifier !== undefined) {
			return (
				unitList.findIndex(
					(el) => el.unitIdentifier === unit.unitIdentifier
				) !== -1
			);
		} else {
			return false;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{displayUnit
						? 'Wohnbereich anzeigen'
						: [isNewUnit ? 'Wohnbereich anlegen' : 'Wohnbereich bearbeiten']}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Wohnbereich'
								name='unitIdentifier'
								id='outlined-size-normal'
								value={unit.unitIdentifier}
								onChange={handleInputChange}
								inputProps={{ maxLength: 50 }}
								required
								disabled={displayUnit || !isNewUnit}
								error={
									isUnitIdAlreadyAssigned(unit) &&
									isNewUnit &&
									isInputKeycloakConform(unit.unitIdentifier)
								}
								helperText={
									isInputKeycloakConform(unit.unitIdentifier)
										? 'Keine Sonderzeichen und Umlaute erlaubt!'
										: isUnitIdAlreadyAssigned(unit) && isNewUnit
										? 'Wohnbereich bereits vorhanden!'
										: ''
								}
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Beschreibung'
								name='description'
								id='outlined-size-normal'
								value={unit?.description}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
								disabled={displayUnit}
							/>
						</Grid>
						{displayUnit && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung durch'
									name='userLastChange'
									id='outlined-size-normal'
									value={unit?.userLastChange}
									disabled
								/>
							</Grid>
						)}
						{displayUnit && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung am'
									name='lastChanged'
									id='outlined-size-normal'
									value={formatDate(unit?.lastChanged)}
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} {displayUnit ? 'Schließen' : 'Abbrechen'}
									</Button>
								</Grid>
								{!displayUnit && (
									<Grid item>
										<Button
											variant='contained'
											disabled={!isSaveButtonEnabled(unit)}
											onClick={isNewUnit ? saveUnit : updateUnit}>
											{<Save />} Speichern
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
