import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	ThemeProvider,
	Button,
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import { Close } from '@mui/icons-material';

import theme from '../theme';
import DisplayContent from './DisplayContent';

interface DisplayLocalMediaProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	filePathInPublicDir: string;
	mediaType: 'image' | 'pdf' | 'video' | 'audio' | 'text';
}

export default function DisplayLocalMedia(prop: DisplayLocalMediaProp) {
	const { open, setOpen, filePathInPublicDir, mediaType } = prop;
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const getFileNameForDownload = () => {
		if (filePathInPublicDir.match('BenutzerleitfadenCIAM')) {
			return 'Benutzerleitfaden CIAM.pdf';
		} else if (filePathInPublicDir.match('Biografiefragen')) {
			return 'Biografiefragen.pdf';
			} else if (filePathInPublicDir.match('Zertifikate')) {
			return 'Dokumentation Einrichtung neuer Zertifikate.pdf';
		} else {
			return filePathInPublicDir;
		}
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth={true}
					maxWidth='lg'>
					<Grid item xs={12} marginRight={2} marginTop={2}>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item>
								<a
									href={filePathInPublicDir}
									download={getFileNameForDownload()}
									target='_blank'>
									<Button>
										<DownloadForOfflineIcon color='primary' fontSize='large' />
									</Button>
								</a>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<DialogTitle>{getFileNameForDownload()}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DisplayContent
										mediaFile={process.env.PUBLIC_URL + filePathInPublicDir}
										mediaType={mediaType}
										title={getFileNameForDownload()}
										setAlertText={setAlertText}
										setShowErrorAlert={setShowErrorAlert}
									/>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
