/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnitMarkedForDeletion
 */
export interface UnitMarkedForDeletion {
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    unitIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    unitDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitMarkedForDeletion
     */
    deletionReservationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitMarkedForDeletion
     */
    modifyingUser?: string;
}

/**
 * Check if a given object implements the UnitMarkedForDeletion interface.
 */
export function instanceOfUnitMarkedForDeletion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitMarkedForDeletionFromJSON(json: any): UnitMarkedForDeletion {
    return UnitMarkedForDeletionFromJSONTyped(json, false);
}

export function UnitMarkedForDeletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitMarkedForDeletion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'unitIdentifier': !exists(json, 'unitIdentifier') ? undefined : json['unitIdentifier'],
        'unitDescription': !exists(json, 'unitDescription') ? undefined : json['unitDescription'],
        'deletionReservationId': !exists(json, 'deletionReservationId') ? undefined : json['deletionReservationId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
    };
}

export function UnitMarkedForDeletionToJSON(value?: UnitMarkedForDeletion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'unitIdentifier': value.unitIdentifier,
        'unitDescription': value.unitDescription,
        'deletionReservationId': value.deletionReservationId,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
    };
}

