import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Residence } from '../generated';
import ResidenceCard from './ResidenceCard';

interface ResidenceGridViewProps {
	residenceList: Residence[];
	handleDisplayResidence: any;
	isAuthorizedGlobalAdmin: boolean;
	isAuthorizedTenantAdmin: boolean;
	handleClickUnits: any;
	handleClickMarkForDeletion: any;
	getMarkedForDeletion: any;
	handleClickDeletionDecision: any;
	handleClickDelete:any
}

export default function ResidenceGridView({
	residenceList,
	handleDisplayResidence,
	isAuthorizedGlobalAdmin,
	isAuthorizedTenantAdmin,
	handleClickUnits,
	handleClickMarkForDeletion,
	getMarkedForDeletion,
	handleClickDeletionDecision,
	handleClickDelete
}: ResidenceGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{residenceList?.length > 0 ? '' : 'Keine Wohnheime hinterlegt.'}
			</Grid>
			{residenceList.map((residence) => (
				<Grid
					item
					xs={3}
					sm={3}
					md={3}
					lg={3}
					key={residence.residenceIdentifier}>
					<ResidenceCard
						residence={residence}
						onClickCard={() => handleDisplayResidence(residence)}
						isAuthorizedGlobalAdmin={isAuthorizedGlobalAdmin}
						isAuthorizedTenantAdmin={isAuthorizedTenantAdmin}
						handleClickUnits={() => handleClickUnits(residence)}
						handleClickMarkForDeletion={handleClickMarkForDeletion}
						getMarkedForDeletion={getMarkedForDeletion}
						handleClickDeletionDecision={handleClickDeletionDecision}
						handleClickDelete={() => handleClickDelete(residence)}
					/>
				</Grid>
			))}
		</Grid>
	);
}
