import { useEffect, useState, useCallback } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	useMediaQuery,
	useTheme,
	Typography,
	Paper,
	DialogContentText,
} from '@mui/material';
import { Save, Close } from '@mui/icons-material';
import { getNetworkApi } from './keycloak';
import {
	getAdminGroupName,
	getUserNameWithTenantSuffix,
	isEmptyField,
	isInputKeycloakConform,
} from './format';
import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import { Group, User, UserInsertParameters } from '../generated/models';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { UserUpdateParameters } from '../generated/models/UserUpdateParameters';
import { useLocation } from 'react-router-dom';
import React from 'react';

interface UserFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedUser: User;
	isNewUser: boolean;
	currentUsers: User[];
	isAuthorizedAdmin: boolean;
	isAuthorizedUserMgmt: boolean;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserForm({
	open,
	setOpen,
	selectedUser,
	isNewUser,
	currentUsers,
	isAuthorizedAdmin,
	isAuthorizedUserMgmt,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: UserFormProps) {
	const [user, setUser] = useState(selectedUser as User);

	const [availableGroupList, setAvailableGroupList] = useState([] as Group[]);
	const [selectedGroups, setSelectedGroups] = useState([] as Group[]);
	const [assignedGroups, setAssignedGroups] = useState([] as any[]);
	const [initialPassword, setInitialPassword] = useState(
		null as unknown as string
	);

	const location = useLocation();
	const { promiseInProgress } = usePromiseTracker();

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setUser({
			...user,
			[name]: value,
		});
	};

	const handleInputChangePassword = (e: any) => {
		const { name, value } = e.target;
		setInitialPassword(value);
	};

	const tenantIdentifier = location.pathname.substring(
		location.pathname.indexOf('tenantId=') + 9,
		location.pathname.indexOf('/users')
	);

	const getRelevantGroupsUserMgmt = (groups: Group[]): Group[] => {
		if (isAuthorizedAdmin) {
			return groups;
		} else if (isAuthorizedUserMgmt) {
			return groups.filter(
				(group) => group.name !== getAdminGroupName(tenantIdentifier)
			);
		} else return [] as Group[];
	};

	useEffect(() => {
		const loadGroupList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getGroups(tenantIdentifier);
				setAvailableGroupList(getRelevantGroupsUserMgmt(result));
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the group data a!');
						setShowErrorAlert(true);
						setAlertText('Gruppen konnten nicht abgerufen werden');
					}
				} else {
					console.log('There was an error fetching the group data!');
					setShowErrorAlert(true);
					setAlertText('Gruppen konnten nicht abgerufen werden');
				}
			}
		};
		trackPromise(loadGroupList());
	}, [tenantIdentifier]);

	useEffect(() => {
		if (!isNewUser) {
			const loadAssignedGroupList = async () => {
				const api = getNetworkApi();
				try {
					const userGroups = await api.getUserGroupsById(user.userId);
					let allGroups: any = await api.getGroups(tenantIdentifier);
					allGroups.forEach((group: any) => {
						group.tableData = {
							checked: userGroups.some(
								({ groupId }) => group.groupId === groupId
							),
						};
					});
					setAssignedGroups(getRelevantGroupsUserMgmt(allGroups));
					setSelectedGroups(getRelevantGroupsUserMgmt(userGroups));
				} catch (error: any) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							console.log('There was an error fetching the group data!');
							setShowErrorAlert(true);
							setAlertText(
								'Gruppen für den Nutzer konnten nicht abgerufen werden'
							);
						}
					} else {
						console.log('There was an error fetching the group data!');
						setShowErrorAlert(true);
						setAlertText(
							'Gruppen für den Nutzer konnten nicht abgerufen werden'
						);
					}
				}
			};
			trackPromise(loadAssignedGroupList());
		}
	}, [tenantIdentifier]);

	const saveNewUser = async () => {
		setOpen(false);
		const userPostRequest: User = user;
		userPostRequest.username = getUserNameWithTenantSuffix(
			userPostRequest.username,
			tenantIdentifier
		);
		userPostRequest.tenantIdentifier = tenantIdentifier;
		const client = getNetworkApi();
		let userInsertParameters: UserInsertParameters = {
			user: userPostRequest,
			groups: selectedGroups ? selectedGroups : [],
			initialPassword: initialPassword,
		};
		try {
			await client.postUser(userInsertParameters);
			setAlertText('Nutzer:in wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Nutzer:in wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateUser = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let userUpdateParameters: UserUpdateParameters = {
			user: user,
			groups: selectedGroups ? selectedGroups : [],
			initialPassword: initialPassword,
		};
		try {
			await client.putUser(user.userId, userUpdateParameters);
			setAlertText('Nutzer:in wurde erfolgreich aktualisiert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Nutzer:in wurde nicht aktualisiert');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = () => {
		if (isNewUser) {
			return (
				!isEmptyField(user?.username) &&
				!currentUsers
					.map((x) => x.username)
					.includes(
						getUserNameWithTenantSuffix(user.username, tenantIdentifier)
					) &&
				!isInputKeycloakConform(user?.username) &&
				!isEmptyField(user?.firstName) &&
				!isEmptyField(user?.lastName) &&
				!isEmptyField(initialPassword)
			);
		} else {
			return (
				!isEmptyField(user?.username) &&
				!isEmptyField(user?.firstName) &&
				!isEmptyField(user?.lastName)
			);
		}
	};

	const columns: Column<Group>[] = [
		{
			title: 'Gruppenname',
			render: useCallback((data: Group) => {
				return (
					<Typography fontSize={16} color={'primary'} variant={'body2'}>
						{data.name}
					</Typography>
				);
			}, []),
		},
	];

	const handleSelectionChange = (rows: Group[]) => {
		setSelectedGroups(rows);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [userList, setUserList] = useState([] as User[]);

	useEffect(() => {
		const loadUserList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUsers(tenantIdentifier);
				setUserList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the user data!');
						setShowErrorAlert(true);
						setAlertText(
							'Nutzerinnen und Nutzer konnten nicht abgerufen werden'
						);
					}
				} else {
					console.log('There was an error fetching the email data!');
					setShowErrorAlert(true);
					setAlertText('Nutzerinnen und Nutzer konnten nicht abgerufen werden');
				}
			}
		};
		trackPromise(loadUserList());
	}, [setShowSuccessAlert]);

	const isUserNameAlreadyAssigned = (user: User) => {
		if (user?.username !== undefined) {
			const realUserName = getUserNameWithTenantSuffix(
				user.username,
				tenantIdentifier
			);
			return userList.findIndex((el) => el.username === realUserName) !== -1;
		} else {
			return false;
		}
	};

	const getEmptyDataMessage = () => {
		if (promiseInProgress) {
			return 'Gruppen werden geladen ...';
		} else {
			return 'Keine Gruppen vorhanden.';
		}
	};

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth='md'>
				<DialogTitle>
					{isNewUser ? 'Nutzer: in anlegen' : 'Nutzer: in bearbeiten'}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={4} marginTop={1}>
							<TextField
								fullWidth
								label='Benutzername'
								name='username'
								id='outlined-size-normal'
								disabled={!isNewUser}
								value={user?.username}
								onChange={handleInputChange}
								required
								error={
									isUserNameAlreadyAssigned(user) &&
									isNewUser &&
									isInputKeycloakConform(user?.username)
								}
								helperText={
									isInputKeycloakConform(user?.username)
										? 'Keine Sonderzeichen und Umlaute erlaubt!'
										: isUserNameAlreadyAssigned(user) && isNewUser
										? 'Benutzername bereits vorhanden!'
										: ''
								}
							/>
						</Grid>
						{isNewUser && (
							<Grid item xs={4} marginTop={1}>
								<Typography align='left' marginTop={2} marginLeft={-2}>
									@{tenantIdentifier}
								</Typography>
							</Grid>
						)}
						<Grid item xs={4} marginTop={1}>
							<TextField
								fullWidth
								label='neues Initialpasswort'
								name='initialPassword'
								id='outlined-size-normal'
								value={initialPassword}
								onChange={handleInputChangePassword}
								inputProps={{
									maxLength: 32,
								}}
								required={isNewUser}
							/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								fullWidth
								label='Vorname'
								name='firstName'
								id='outlined-size-normal'
								value={user?.firstName}
								onChange={handleInputChange}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label='Nachname'
								name='lastName'
								id='outlined-size-normal'
								value={user?.lastName}
								onChange={handleInputChange}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<MaterialTable
								localization={{
									body: {
										emptyDataSourceMessage: getEmptyDataMessage(),
									},
								}}
								icons={MaterialTableIcons()}
								columns={columns}
								data={isNewUser ? availableGroupList : assignedGroups}
								components={{
									Container: (props) => <Paper {...props} elevation={0} />,
									// workaround to left align table title: https://github.com/mbrn/material-table/issues/1690
									Toolbar: (props) => {
										const propsCopy = { ...props };
										propsCopy.showTitle = false;
										return (
											<Grid container direction='row'>
												<Grid
													item
													xs={3}
													md={6}
													lg={12}
													marginBottom={-20}
													marginTop={2}>
													<DialogContentText>Gruppen:</DialogContentText>
												</Grid>
												<Grid item xs={6}>
													<MTableToolbar {...propsCopy} />
												</Grid>
											</Grid>
										);
									},
								}}
								options={{
									paging: false,
									sorting: false,
									filtering: false,
									search: false,
									rowStyle: { fontSize: 24 },
									selection: true,
									showTextRowsSelected: false,
								}}
								onSelectionChange={handleSelectionChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />}
										{'Abbrechen'}
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										onClick={isNewUser ? saveNewUser : updateUser}
										disabled={!isSaveButtonEnabled()}>
										{<Save />} Speichern
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
