/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailclientConfig
 */
export interface MailclientConfig {
    /**
     * 
     * @type {number}
     * @memberof MailclientConfig
     */
    mailclientConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof MailclientConfig
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailclientConfig
     */
    techValidTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    modifyingUser?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    host?: string;
    /**
     * 
     * @type {number}
     * @memberof MailclientConfig
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    folder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfig
     */
    isStartTLSEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfig
     */
    isSSLEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfig
     */
    isOAUTH2Enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    authMechanism?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    clientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfig
     */
    refreshToken?: string;
}

/**
 * Check if a given object implements the MailclientConfig interface.
 */
export function instanceOfMailclientConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MailclientConfigFromJSON(json: any): MailclientConfig {
    return MailclientConfigFromJSONTyped(json, false);
}

export function MailclientConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailclientConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mailclientConfigId': !exists(json, 'mailclientConfigId') ? undefined : json['mailclientConfigId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'techValidTo': !exists(json, 'techValidTo') ? undefined : (new Date(json['techValidTo'])),
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'isStartTLSEnabled': !exists(json, 'isStartTLSEnabled') ? undefined : json['isStartTLSEnabled'],
        'isSSLEnabled': !exists(json, 'isSSLEnabled') ? undefined : json['isSSLEnabled'],
        'isOAUTH2Enabled': !exists(json, 'isOAUTH2Enabled') ? undefined : json['isOAUTH2Enabled'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'authMechanism': !exists(json, 'authMechanism') ? undefined : json['authMechanism'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
    };
}

export function MailclientConfigToJSON(value?: MailclientConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mailclientConfigId': value.mailclientConfigId,
        'tenantIdentifier': value.tenantIdentifier,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
        'techValidTo': value.techValidTo === undefined ? undefined : (value.techValidTo.toISOString().substr(0,10)),
        'modifyingUser': value.modifyingUser,
        'host': value.host,
        'port': value.port,
        'protocol': value.protocol,
        'folder': value.folder,
        'isStartTLSEnabled': value.isStartTLSEnabled,
        'isSSLEnabled': value.isSSLEnabled,
        'isOAUTH2Enabled': value.isOAUTH2Enabled,
        'username': value.username,
        'password': value.password,
        'authMechanism': value.authMechanism,
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'refreshToken': value.refreshToken,
    };
}

