/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidenceMarkedForDeletion
 */
export interface ResidenceMarkedForDeletion {
    /**
     * 
     * @type {string}
     * @memberof ResidenceMarkedForDeletion
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMarkedForDeletion
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMarkedForDeletion
     */
    residenceDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ResidenceMarkedForDeletion
     */
    deletionReservationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMarkedForDeletion
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMarkedForDeletion
     */
    modifyingUser?: string;
}

/**
 * Check if a given object implements the ResidenceMarkedForDeletion interface.
 */
export function instanceOfResidenceMarkedForDeletion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResidenceMarkedForDeletionFromJSON(json: any): ResidenceMarkedForDeletion {
    return ResidenceMarkedForDeletionFromJSONTyped(json, false);
}

export function ResidenceMarkedForDeletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceMarkedForDeletion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'residenceDescription': !exists(json, 'residenceDescription') ? undefined : json['residenceDescription'],
        'deletionReservationId': !exists(json, 'deletionReservationId') ? undefined : json['deletionReservationId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
    };
}

export function ResidenceMarkedForDeletionToJSON(value?: ResidenceMarkedForDeletion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'residenceDescription': value.residenceDescription,
        'deletionReservationId': value.deletionReservationId,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
    };
}

