import { TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, styled } from '@mui/material/styles';

// A custom theme for this app
export const theme = createTheme({
	palette: {
		background: {
			default: grey['A100'],
		},
		primary: {
			main: grey[500],
		},
		secondary: {
			main: grey[300],
		},
	},
	typography: {
		allVariants: {
			fontFamily: ['Roboto', 'sans-serif'].join(','),
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 30,
				},
				contained: {
					color: grey[100],
					backgroundColor: grey[500],
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					height: '50px',
					borderRadius: 30,
					width: '90%',
					fontFamily: ['Roboto', 'sans-serif'].join(','),
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: 18,
					color: grey[500],
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: grey[300],
					'&.Mui-selected': {
						color: grey[500],
					},
				},
			},
		},

		MuiSlider: {
			styleOverrides: {
				valueLabel: {
					fontSize: 18,
				},
				markLabel: {
					fontSize: 18,
					transform: 'translateX(-50%)',
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					fontSize: 24,
					color: '#696969', //darkgrey
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					borderRadius: 30,
					width: '96%',
					marginTop: '10px',
					marginLeft: '2%',
					marginRight: '2%',
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					fontSize: 18,
					fontFamily: ['Roboto', 'sans-serif'].join(','),
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					fontSize: 8,
					fontFamily: ['Roboto', 'sans-serif'].join(','),
				},
			},
		},
	},
});

export default theme;

export const TableCellStyled = styled(TableCell)(() => ({
	fontSize: 24,
}));
