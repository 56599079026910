import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Tenant } from '../generated';
import TenantCard from './TenantCard';
import { usePromiseTracker } from 'react-promise-tracker';

interface TenantsGridViewProps {
	tenantList: Tenant[];
	handleDisplayTenant: any;
	handleClickUsers: any;
	handleClickResidences: any;
	handleClickCertificate: any;
	handleClickCertificateRevocation: any;
	handleClickCertificateRenewal: any;
	loadCertificateMetaData: any;
	handleClickDelete: any;
	handleClickMailclientConfig: any;
	userRoleList: string[];
	refresh: number;
}

export default function TenantsGridView({
	tenantList,
	handleDisplayTenant,
	handleClickUsers,
	handleClickResidences,
	handleClickCertificate,
	handleClickCertificateRevocation,
	handleClickCertificateRenewal,
	loadCertificateMetaData,
	handleClickDelete,
	handleClickMailclientConfig,
	userRoleList,
	refresh,
}: TenantsGridViewProps) {
	const { promiseInProgress } = usePromiseTracker();
	const getEmptyDataMessage = () => {
		if (promiseInProgress) {
			return 'Betreiber werden geladen ...';
		} else if (tenantList?.length > 0) {
			return '';
		} else {
			return 'Keine Betreiber hinterlegt.';
		}
	};

	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{getEmptyDataMessage()}
			</Grid>
			{tenantList.map((tenant) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={tenant.tenantIdentifier}>
					<TenantCard
						tenant={tenant}
						onClickCard={() => handleDisplayTenant(tenant)}
						handleClickResidences={() => handleClickResidences(tenant)}
						handleClickUsers={() => handleClickUsers(tenant)}
						handleClickCertificate={() => handleClickCertificate(tenant)}
						handleClickCertificateRevocation={() => handleClickCertificateRevocation(tenant)}
						handleClickCertificateRenewal={() => handleClickCertificateRenewal(tenant)}
						loadCertificateMetaData={() => loadCertificateMetaData(tenant)}
						handleClickDelete={() => handleClickDelete(tenant)}
						handleClickMailclientConfig={() => handleClickMailclientConfig(tenant)}
						userRoleList={userRoleList}
						refresh={refresh}
					/>
				</Grid>
			))}
		</Grid>
	);
}
