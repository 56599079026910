import React, { useState } from 'react';
import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import { formatDate, isEmptyField, isInputKeycloakConform } from './format';
import {
	Residence,
	ResidenceInsertParameter,
	ResidenceUpdateParameter,
} from '../generated';

interface ResidenceFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tenantIdentifier: string;
	isNewResidence: boolean;
	displayResidence: boolean;
	selectedResidence: Residence;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	residenceList: Residence[];
}

export default function ResidenceForm(prop: ResidenceFormProp) {
	const {
		open,
		setOpen,
		isNewResidence,
		displayResidence,
		selectedResidence,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		residenceList,
	} = prop;

	const [residence, setResidence] = useState(selectedResidence);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setResidence({
			...residence,
			[name]: value,
		});
	};

	const saveResidence = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceInsertParameter: ResidenceInsertParameter = {
			description: residence.description,
		};
		try {
			await client.postResidence(
				residence.tenantIdentifier as string,
				residence.residenceIdentifier as string,
				residenceInsertParameter
			);
			setAlertText('Wohnheim wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Wohnheim wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateResidence = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceUpdateParameter: ResidenceUpdateParameter = {
			description: residence.description,
		};
		try {
			await client.putResidence(
				residence.tenantIdentifier as string,
				residence.residenceIdentifier as string,
				residenceUpdateParameter
			);
			setAlertText('Wohnheim wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Wohnheim wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (residence: Residence) => {
		return isNewResidence
			? !isEmptyField(residence?.residenceIdentifier) &&
					!isEmptyField(residence?.description) &&
					!isResidenceIdAlreadyAssigned(residence) &&
					!isInputKeycloakConform(residence.residenceIdentifier)
			: !isEmptyField(residence?.residenceIdentifier) &&
					!isEmptyField(residence?.description);
	};

	const isResidenceIdAlreadyAssigned = (residence: Residence) => {
		if (residence?.residenceIdentifier !== undefined) {
			return (
				residenceList.findIndex(
					(el) => el.residenceIdentifier === residence.residenceIdentifier
				) !== -1
			);
		} else {
			return false;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{displayResidence
						? 'Wohnheim anzeigen'
						: [isNewResidence ? 'Wohnheim anlegen' : 'Wohnheim bearbeiten']}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Wohnheim'
								name='residenceIdentifier'
								id='outlined-size-normal'
								value={residence.residenceIdentifier}
								onChange={handleInputChange}
								inputProps={{ maxLength: 50 }}
								required
								disabled={displayResidence || !isNewResidence}
								error={
									isResidenceIdAlreadyAssigned(residence) &&
									isNewResidence &&
									isInputKeycloakConform(residence.residenceIdentifier)
								}
								helperText={
									isInputKeycloakConform(residence.residenceIdentifier)
										? 'Keine Sonderzeichen und Umlaute erlaubt!'
										: isResidenceIdAlreadyAssigned(residence) && isNewResidence
										? 'Wohnheim bereits vorhanden!'
										: ''
								}
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Beschreibung'
								name='description'
								id='outlined-size-normal'
								value={residence?.description}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
								disabled={displayResidence}
							/>
						</Grid>
						{displayResidence && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung durch'
									name='userLastChange'
									id='outlined-size-normal'
									value={residence?.userLastChange}
									disabled
								/>
							</Grid>
						)}
						{displayResidence && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung am'
									name='lastChanged'
									id='outlined-size-normal'
									value={formatDate(residence?.lastChanged)}
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} {displayResidence ? 'Schließen' : 'Abbrechen'}
									</Button>
								</Grid>
								{!displayResidence && (
									<Grid item>
										<Button
											variant='contained'
											disabled={!isSaveButtonEnabled(residence)}
											onClick={
												isNewResidence ? saveResidence : updateResidence
											}>
											{<Save />} Speichern
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
