import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import { getNetworkApi } from '../components/keycloak';
import {
	ExecuteUnitDeletionDecisionRequest,
	PostUnitDeletionReservationRequest,
	Unit,
	UnitMarkedForDeletion
} from '../generated';
import { trackPromise } from 'react-promise-tracker';
import React from 'react';
import {
	isAuthorizedForGlobalAdminRole,
	isAuthorizedForTenantAdminRole,
	TenantData,
} from '../components/format';
import UnitTableView from '../components/UnitTableView';
import UnitGridView from '../components/UnitGridView';
import UnitForm from '../components/UnitForm';
import Confirm from '../components/Confirm';

export default function Units(props: any) {
	const tenantData: TenantData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
	};

	const [userRoleList, setUserRoleList] = React.useState([] as string[]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [isNewUnit, setIsNewUnit] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [displayUnit, setDisplayUnit] = useState(false);
	const [showUnitForm, setShowUnitForm] = useState(false);

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [selectedUnit, setSelectedUnit] = useState({} as Unit);
	const [unitList, setUnitList] = useState([] as Unit[]);

	const tenantIdentifier = tenantData.tenantIdentifier;

	const [showConfirmMarkForDeletion, setShowConfirmMarkForDeletion] = useState(false);
	const [showConfirmDeletionDecision, setShowConfirmDeletionDecision] = useState(false);
	const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
	const [deleteText, setDeleteText] = useState('');
	const [deletionReservation, setDeletionReservation] = useState({} as PostUnitDeletionReservationRequest);
	const [markedForDeletionList, setMarkedForDeletionList] = useState([] as UnitMarkedForDeletion[]);
	const [deletionDecision, setDeletionDecision] = useState({} as ExecuteUnitDeletionDecisionRequest);
	const [selectedUnitForDeletion, setSelectedUnitForDeletion] = useState({} as Unit);

	const isAuthorizedGlobalAdmin: boolean = useMemo(() => {
		return isAuthorizedForGlobalAdminRole(userRoleList);
	}, [userRoleList]);

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	useEffect(() => {
		const loadUnits = async () => {
			const api = getNetworkApi();
			if (tenantData) {
				try {
					const result = await api.getUnits(
						tenantData.tenantIdentifier as string,
						tenantData.residenceIdentifier as string
					);
					setUnitList(result);
				} catch (error: any) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Wohnbereiche konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Wohnbereiche konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadUnits());
	}, [selectedUnit, refresh, showSuccessAlert]);

	useEffect(() => {
		const loadMarkedForDeletionList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnitsMarkedForDeletion(tenantData.tenantIdentifier, tenantData.residenceIdentifier);
				setMarkedForDeletionList(result);

			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the Marked for Deletion data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the Marked for Deletion data!'
					);
				}
			}
		};
		trackPromise(loadMarkedForDeletionList());
	}, [showSuccessAlert, refresh]);

	const handleClickOpenUnitForm = (isNewUnit: boolean, unit: Unit) => {
		setSelectedUnit({
			tenantIdentifier: tenantData.tenantIdentifier,
			residenceIdentifier: tenantData.residenceIdentifier,
		} as Unit);
		if (!isNewUnit) {
			setSelectedUnit(unit);
		}
		setIsNewUnit(isNewUnit);
		setShowUnitForm(true);
		setDisplayUnit(false);
	};

	const handleDisplayUnit = (unit: Unit) => {
		setDisplayUnit(true);
		setShowUnitForm(true);
		setSelectedUnit(unit);
		setIsNewUnit(false);
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const [isTableViewSelected, setIsTableViewSelected] = React.useState(false);
	const handleSwitchChange = () => {
		setIsTableViewSelected(!isTableViewSelected);
	};


	const handleClickMarkForDeletion = (deletionReservation: PostUnitDeletionReservationRequest) => {
		setShowConfirmMarkForDeletion(true);
		setDeleteText('Wollen Sie wirklich den Wohnbereich ' + deletionReservation.unitIdentifier + ' zum Löschen vormerken?');
		setDeletionReservation(deletionReservation);
	};

	const markForDeletion = async () => {
			const client = getNetworkApi();
			try {
				await client.postUnitDeletionReservation(
					deletionReservation.tenantIdentifier,
					deletionReservation.residenceIdentifier,
					deletionReservation.unitIdentifier,
					deletionReservation.unitDeletionReservationInsertParameters);
				setAlertText(
					'Wohnbereich ' + deletionReservation.unitIdentifier + ' wurde erfolgreich markiert zur endgültigen Löschung.'
				);

				setShowConfirmMarkForDeletion(false);
				setDeletionReservation({} as PostUnitDeletionReservationRequest);
				setShowSuccessAlert(true);
			} catch (error) {
				setAlertText('Wohnbereich wurde nicht markiert zur endgültigen Löschung');
				setShowErrorAlert(true);
			}

	};

	const getMarkedForDeletion = (unit: Unit): UnitMarkedForDeletion | undefined => {
		let filteredList: UnitMarkedForDeletion[] =
			markedForDeletionList.filter(function (el) {
				return el.tenantIdentifier === unit.tenantIdentifier &&
					el.residenceIdentifier === unit.residenceIdentifier &&
					el.unitIdentifier === unit.unitIdentifier
			});
		if (filteredList.length === 1) {
			return filteredList.at(0);
		} else {
			return undefined;
		}

	}

	const handleClickDeletionDecision = (
		deletionReservationDecision: ExecuteUnitDeletionDecisionRequest,
	) => {
		setShowConfirmDeletionDecision(true);
		deletionReservationDecision.unitDeletionDecision?.isPerformDeletion
			? setDeleteText(
				'Wollen Sie wirklich den Wohnbereich ' +
				deletionReservationDecision.unitIdentifier +
				' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.'
			)
			: setDeleteText(
				'Wollen Sie wirklich die Löschung für den Wohnbereich ' +
				deletionReservationDecision.unitIdentifier +
				' rückgängig machen?'
			);

		setDeletionDecision(deletionReservationDecision)
	};

	const finalDeletionDecision = async () => {
		const client = getNetworkApi();
		try {
			await client.executeUnitDeletionDecision(
				deletionDecision.tenantIdentifier,
				deletionDecision.residenceIdentifier,
				deletionDecision.unitIdentifier,
				deletionDecision.unitDeletionReservationId,
				deletionDecision.unitDeletionDecision
			);

			setShowSuccessAlert(true);
			setShowConfirmDeletionDecision(false);
			deletionDecision.unitDeletionDecision?.isPerformDeletion
				? setAlertText('Wohnbereich wurde erfolgreich gelöscht')
				: setAlertText('Löschvermerk für den Wohnbereich wurde zurückgenommen');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Wohnbereich konnte nicht gelöscht werden');
			console.log('There was an error while deleting!');
		}
	};

	const handleClickDelete = (unit: Unit) => {
		setShowConfirmDeletion(true);
		setDeleteText('Wollen Sie wirklich den Wohnbereich ' + unit.unitIdentifier + ' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.');
		setSelectedUnitForDeletion(unit);
	};

	const deleteUnit = async () => {
		const client = getNetworkApi();
		try {
			await client.postUnitDeletion(
				selectedUnitForDeletion.tenantIdentifier as string,
				selectedUnitForDeletion.residenceIdentifier as string,
				selectedUnitForDeletion.unitIdentifier as string
			);
			setAlertText(
				'Wohnbereich ' + selectedUnitForDeletion.unitIdentifier + ' wurde erfolgreich endgültig gelöscht.'
			);

			setShowConfirmDeletion(false);
			setSelectedUnitForDeletion({} as Unit);
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Wohnbereich wurde nicht gelöscht.');
			setShowErrorAlert(true);
			setShowConfirmDeletion(false);
		}
	};

	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2, marginBottom: 5 }}>
					<Button
						disabled={!isAuthorizedTenantAdmin}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenUnitForm(true, {})}>
						{<Add />} Wohnbereich
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip title={isTableViewSelected ? 'Kärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{isTableViewSelected ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					{isTableViewSelected ? (
						<UnitTableView
							unitList={unitList}
							isAuthorized={isAuthorizedTenantAdmin}
							handleClickOpenUnitForm={handleClickOpenUnitForm}
						/>
					) : (
						<UnitGridView
							unitList={unitList}
							handleDisplayUnit={handleDisplayUnit}
							isAuthorizedGlobalAdmin={isAuthorizedGlobalAdmin}
							isAuthorizedTenantAdmin={isAuthorizedTenantAdmin}
							handleClickMarkForDeletion={handleClickMarkForDeletion}
							getMarkedForDeletion={getMarkedForDeletion}
							handleClickDeletionDecision={handleClickDeletionDecision}
							handleClickDelete={handleClickDelete}
						/>
					)}
				</Grid>
			</Grid>

			{showUnitForm && (
				<UnitForm
					open={showUnitForm}
					setOpen={setShowUnitForm}
					tenantIdentifier={tenantData.tenantIdentifier}
					residenceIdentifier={tenantData.residenceIdentifier}
					isNewUnit={isNewUnit}
					displayUnit={displayUnit}
					selectedUnit={selectedUnit}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					unitList={unitList}
				/>
			)}
			{showConfirmMarkForDeletion && (
				<Confirm
					open={showConfirmMarkForDeletion}
					setOpen={setShowConfirmMarkForDeletion}
					text={deleteText}
					executefct={markForDeletion}
				/>
			)}
			{showConfirmDeletionDecision && (
				<Confirm
					open={showConfirmDeletionDecision}
					setOpen={setShowConfirmDeletionDecision}
					text={deleteText}
					executefct={finalDeletionDecision}
				/>
			)}
			{showConfirmDeletion && (
				<Confirm
					open={showConfirmDeletion}
					setOpen={setShowConfirmDeletion}
					text={deleteText}
					executefct={deleteUnit}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
