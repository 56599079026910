import { useKeycloak } from '@react-keycloak/web';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Tooltip,
	IconButton,
	Divider
} from '@mui/material';
import { ArrowBack, Logout, Help } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import icon_tcc from '../data/icon_tcc_without_background.svg';
import DisplayLocalMedia from './DisplayLocalMedia';
import { isLandscape, ParsedToken } from './format';
import { useLocation, useHistory } from 'react-router-dom';
import React, {useState} from 'react';

const Menu = () => {
	const { keycloak } = useKeycloak();
	const [showUserGuidelinesFile, setShowUserGuidelinesFile] = useState(false);
	const [showCertificateGuidelinesFile, setShowCertificateGuidelinesFile] = useState(false);
	const location = useLocation();
	const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
	const history = useHistory();

	const handleShowUserGuidelines = () => {
		setShowUserGuidelinesFile(true);
	};
	const handleShowCertificateGuidelines = () => {
		setShowCertificateGuidelinesFile(true);
	};

	const Home = () => {
		const isStartPage = location.pathname === '/';
		const history = useHistory();

		const revertToHome = () => {
			history.replace('/');
		};

		return (
			<IconButton
				disabled={isStartPage}
				color={!isStartPage ? 'primary' : 'secondary'}
				aria-label='Zurück zur Übersicht'
				component='span'
				onClick={revertToHome}>
				<HomeIcon sx={{ fontSize: '30px' }} />
			</IconButton>
		);
	};

	const BackButton = () => {
		const hasNoRevertButton = location.pathname === '/' || !location.key;

		const revertBack = () => {
			history.goBack();
		};
		if (!hasNoRevertButton) {
			return (
				<IconButton color='primary' component='span' onClick={revertBack}>
					<ArrowBack sx={{ fontSize: '30px' }} />
				</IconButton>
			);
		} else {
			return <></>;
		}
	};

	const HelpButton = () => {
		return (
			<Tooltip
				sx={{ width: 'maxContent' }}
				title={'Benutzerleitfaden CIAM anzeigen'}>
				<span>
					<IconButton
						color='primary'
						component='span'
						sx={{ marginLeft: 'auto', marginRight: 'auto' }}
						onClick={handleShowUserGuidelines}>
						<Help sx={{ fontSize: '30px' }} color='primary' elevation={5} />
					</IconButton>
				</span>
			</Tooltip>
		);
	};
	const CertificateButton = () => {
		return (
			<Tooltip
				sx={{ width: 'maxContent' }}
				title={'Dokumentation Einrichtung neuer Zertifikate anzeigen'}>
				<span>
					<IconButton
						color='primary'
						component='span'
						sx={{ marginLeft: 'auto', marginRight: 'auto' }}
						onClick={handleShowCertificateGuidelines}>
						<WorkspacePremiumIcon sx={{ fontSize: '30px' }} color='primary' elevation={5} />
					</IconButton>
				</span>
			</Tooltip>
		);
	};

	function MenuTitle({ path }: { path: any }) {
		let heading = '';
		let icon;

		if (path.pathname.match('/units') != null) {
			const residenceIdentifier = location.pathname.substring(
				location.pathname.indexOf('residenceId=') + 12,
				location.pathname.indexOf('/units')
			);
			const tenantIdentifier = location.pathname.substring(
				location.pathname.indexOf('tenantId=') + 9,
				location.pathname.indexOf('/residences')
			);
			heading =
				'Wohnbereiche des Wohnheims ' +
				residenceIdentifier +
				' (Träger ' +
				tenantIdentifier +
				')';
			icon = '';
		} else if (path.pathname.match('/residences') != null) {
			const tenantIdentifier = location.pathname.substring(
				location.pathname.indexOf('tenantId=') + 9,
				location.pathname.indexOf('/residences')
			);
			heading = 'Wohnheime des Trägers ' + tenantIdentifier;
			icon = '';
		} else if (path.pathname.match('/users') != null) {
			const tenantIdentifier = location.pathname.substring(
				location.pathname.indexOf('tenantId=') + 9,
				location.pathname.indexOf('/users')
			);
			heading = 'Nutzer des Trägers ' + tenantIdentifier;
			icon = '';
		} else if (parsedToken) {
			heading = 'Übersicht der Träger';
			icon = '';
		} else {
			heading = '';
			icon = '';
		}

		return (
			<Typography
				variant={isLandscape() ? 'h5' : undefined}
				sx={{
					margin: 'auto',
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
				}}>
				{icon}
				{heading}
			</Typography>
		);
	}

	const onClickLogout = () => {
		history.replace('/');
		keycloak.logout();
	};

	const LogoutButton = () => {
		return (
			<Tooltip title={'Abmelden'}>
				<span>
					<IconButton color='primary' component='span' onClick={onClickLogout}>
						<Logout sx={{ fontSize: '30px' }} color='primary' elevation={5} />
					</IconButton>
				</span>
			</Tooltip>
		);
	};

	let conditionalStyle = isLandscape()
		? { marginLeft: 2, marginRight: 2 }
		: { marginLeft: 0, marginRight: 0 };

	const onLogin = () => {
		keycloak.init({
			onLoad: 'check-sso',
			pkceMethod: 'S256',
			silentCheckSsoRedirectUri:
				window.location.origin + '/silent-check-sso.html',
		});
		keycloak.login();
	};

	return (
		<React.Fragment>
			<AppBar
				color='secondary'
				style={{ borderRadius: '20px' }}
				position='sticky'>
				<Toolbar>
					{keycloak && !keycloak.authenticated && (
						<Button
							variant='contained'
							sx={{ marginLeft: 'auto' }}
							onClick={onLogin}>
							Anmelden
						</Button>
					)}
					{keycloak && keycloak.authenticated && (
						<React.Fragment>
							<img src={icon_tcc} width={50} alt='' />
							<Divider
								orientation='vertical'
								flexItem={true}
								variant='middle'
								sx={{
									marginLeft: '1%',
								}}
							/>
							<Typography sx={conditionalStyle} variant='h6'>
								Hallo {parsedToken?.given_name}
							</Typography>
							<Home />
							<BackButton />
							<MenuTitle path={location} />
							<CertificateButton/>
							<HelpButton />
							<LogoutButton />
						</React.Fragment>
					)}
				</Toolbar>
			</AppBar>
			{showUserGuidelinesFile && (
				<DisplayLocalMedia
					open={showUserGuidelinesFile}
					setOpen={setShowUserGuidelinesFile}
					filePathInPublicDir='/data/BenutzerleitfadenCIAM.pdf'
					mediaType={'pdf'}
				/>
			)}
			{showCertificateGuidelinesFile && (
				<DisplayLocalMedia
					open={showCertificateGuidelinesFile}
					setOpen={setShowCertificateGuidelinesFile}
					filePathInPublicDir='/data/Zertifikate.pdf'
					mediaType={'pdf'}
				/>
			)}
		</React.Fragment>
	);
};

export default Menu;
