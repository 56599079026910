/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    unitIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof Unit
     */
    lastChanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    userLastChange?: string;
}

/**
 * Check if a given object implements the Unit interface.
 */
export function instanceOfUnit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'unitIdentifier': !exists(json, 'unitIdentifier') ? undefined : json['unitIdentifier'],
        'lastChanged': !exists(json, 'lastChanged') ? undefined : (new Date(json['lastChanged'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userLastChange': !exists(json, 'userLastChange') ? undefined : json['userLastChange'],
    };
}

export function UnitToJSON(value?: Unit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'unitIdentifier': value.unitIdentifier,
        'lastChanged': value.lastChanged === undefined ? undefined : (value.lastChanged.toISOString().substr(0,10)),
        'description': value.description,
        'userLastChange': value.userLastChange,
    };
}

