import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
	ThemeProvider,
	FormControl,
	useMediaQuery,
	useTheme,
	Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Save, Close } from '@mui/icons-material';
import FileUploader from './FileUploader';
import { getNetworkApi } from './keycloak';
import { MakeDisabledTextField } from './format';
import { User } from '../generated';

interface UploadUserFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tenantIdentifier: string;
	setUsersFromFile: React.Dispatch<React.SetStateAction<User[]>>;
	setShowUsersFromFileForm: React.Dispatch<React.SetStateAction<boolean>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UploadUserFileForm({
	open,
	setOpen,
	tenantIdentifier,
	setUsersFromFile,
	setShowUsersFromFileForm,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: UploadUserFileFormProps) {
	const [selectedFile, setSelectedFile] = useState({} as File);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	function isSaveButtonEnabled() {
		return isFilePicked;
	}

	const handleSelectedFile = (event: { target: { files: any[] } }) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setSelectedFileName(file?.name);

		setIsFilePicked(true);
	};

	const uploadUserFile = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			const result = await client.postUserFile(tenantIdentifier, selectedFile);
			setUsersFromFile(result);
			setShowUsersFromFileForm(true);
		} catch (error) {
			setAlertText('Datei mit Mitarbeiterdaten wurde nicht importiert');
			setShowErrorAlert(true);
		}
	};

	function SampleDataTable() {
		function createData(userName: string, name: string) {
			return { userName, name };
		}

		const rows = [createData('simpli', 'Simpson, Lisa')];

		return (
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 650, fontStyle: 'italic' }}
					size='small'
					aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell align='left'>Nutzername</TableCell>
							<TableCell>Nachname,&nbsp;Vorname</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.userName}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component='th' scope='row'>
									{row.userName}
								</TableCell>
								<TableCell align='left'>{row.name}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>{'Mitarbeiter:innen aus Datei importieren'}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2} paddingTop={2}>
								<Grid item xs={3}>
									<React.Fragment>
										<FileUploader onChange={handleSelectedFile} />
									</React.Fragment>
								</Grid>
								<Grid item xs={9}>
									<FormControl>
										{MakeDisabledTextField(
											'Name',
											'fileName',
											selectedFileName
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										alignItems='left'
										spacing={2}
										paddingBottom={4}>
										<Grid item>
											<Typography sx={{ fontStyle: 'italic' }}>
												Damit die Datei importiert werden kann muss sie die
												folgenden zwei Spalten haben, getrennt durch ein
												Semikolon:
											</Typography>
										</Grid>
										<Grid item>{SampleDataTable()}</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {'Abbrechen'}
											</Button>
										</Grid>
										<Grid item>
											<Button
												variant='contained'
												onClick={uploadUserFile}
												disabled={!isSaveButtonEnabled()}>
												{<Save />} Importieren
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
