/* tslint:disable */
/* eslint-disable */
/**
 * tcc CIAM Backend
 * tcc CIAM Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailclientConfigUpdateParameter
 */
export interface MailclientConfigUpdateParameter {
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    host?: string;
    /**
     * 
     * @type {number}
     * @memberof MailclientConfigUpdateParameter
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    folder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfigUpdateParameter
     */
    isStartTLSEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfigUpdateParameter
     */
    isSSLEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailclientConfigUpdateParameter
     */
    isOAUTH2Enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    authMechanism?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    clientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof MailclientConfigUpdateParameter
     */
    refreshToken?: string;
}

/**
 * Check if a given object implements the MailclientConfigUpdateParameter interface.
 */
export function instanceOfMailclientConfigUpdateParameter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MailclientConfigUpdateParameterFromJSON(json: any): MailclientConfigUpdateParameter {
    return MailclientConfigUpdateParameterFromJSONTyped(json, false);
}

export function MailclientConfigUpdateParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailclientConfigUpdateParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host': !exists(json, 'host') ? undefined : json['host'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'isStartTLSEnabled': !exists(json, 'isStartTLSEnabled') ? undefined : json['isStartTLSEnabled'],
        'isSSLEnabled': !exists(json, 'isSSLEnabled') ? undefined : json['isSSLEnabled'],
        'isOAUTH2Enabled': !exists(json, 'isOAUTH2Enabled') ? undefined : json['isOAUTH2Enabled'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'authMechanism': !exists(json, 'authMechanism') ? undefined : json['authMechanism'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
    };
}

export function MailclientConfigUpdateParameterToJSON(value?: MailclientConfigUpdateParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host': value.host,
        'port': value.port,
        'protocol': value.protocol,
        'folder': value.folder,
        'isStartTLSEnabled': value.isStartTLSEnabled,
        'isSSLEnabled': value.isSSLEnabled,
        'isOAUTH2Enabled': value.isOAUTH2Enabled,
        'username': value.username,
        'password': value.password,
        'authMechanism': value.authMechanism,
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'refreshToken': value.refreshToken,
    };
}

